import React, { useEffect } from "react"

import { Skeleton, FormInstance } from "antd"
import Text from "components/atom/text"
import { Rendering } from "components/molecule/loading"
import { StateMachineType } from "libs/statemachine"

import { useAccountStore } from "@/store/account/useAccountStore"

import AccountUserInfo from "../UserAccount/accountUserInfo"

const CardAccountInfo: React.FC<any> = ({ form }: { form: FormInstance }) => {
  const { accountInfo, executeAccountInfo } = useAccountStore()

  useEffect(() => {
    executeAccountInfo()
  }, [])

  const renderStateNotStarted = () => <Rendering />

  const renderStateLoading = () => (
    <div>
      <Skeleton active />
    </div>
  )

  const renderStateLoaded = () => (
    <div className="mb-4">
      <Text className="mb-2 text-2xl font-bold block">
        Precisamos de mais alguns dados seus
      </Text>

      <AccountUserInfo form={form} />
    </div>
  )

  const rendering = () => {
    switch (accountInfo.viewState) {
      case StateMachineType.NotStarted:
        return renderStateNotStarted()
      case StateMachineType.Loading:
        return renderStateLoading()
      case StateMachineType.Loaded:
        return renderStateLoaded()
      default:
        return renderStateLoading()
    }
  }

  return <>{!accountInfo.data?.completed && rendering()}</>
}

export default CardAccountInfo
