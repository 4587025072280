import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

import { StateMachineType } from "libs/statemachine"
import {
  cleanData,
  FeatureGetter,
  features,
  getData,
} from "store/getter/Action"
import { getterSelector } from "store/getter/Selector"

interface UseGetterStore {
  clean: () => void
  data: any
  getData: (args?: {
    queryParams?: any
    pathParams?: any
    signal?: AbortSignal
    options?: { withCleanData: boolean }
  }) => Promise<any>
  options: any
  viewState: StateMachineType
}

export const useGetterStore = ({
  feature,
}: {
  feature: FeatureGetter
}): UseGetterStore => {
  const dataFeature: any = features[feature]

  const dispatch = useDispatch()

  const { data, viewState }: any = useSelector(getterSelector)

  const _getData = useCallback(
    (params: any = {}) => getData({ feature, ...params })(dispatch),
    [dispatch, feature],
  )

  const _executeReset = useCallback(
    () => cleanData({ feature })(dispatch),
    [dispatch, feature],
  )

  const dataResult = data[feature]

  const options = Array.isArray(dataResult)
    ? dataResult?.map((item: any) => {
        return {
          text: item[dataFeature?.keyText],
          value: item[dataFeature?.keyValue],
        }
      })
    : null

  return {
    clean: _executeReset,
    data: data[feature] || null,
    getData: _getData,
    options,
    viewState: viewState[feature] || StateMachineType.NotStarted,
  }
}
