import React, { lazy } from "react"
import { Provider } from "react-redux"
import { Router } from "react-router"
import { Route, Switch } from "react-router-dom"

import { GTMProvider } from "@elgorditosalsero/react-gtm-hook"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ConfigProvider } from "antd"
import ptBR from "antd/es/locale/pt_BR"
import CreditCard from "components/organism/creditcard"
import history from "libs/history"
import { withSuspense, withSuspensePainel } from "libs/hoc"
import CompanySignUp from "pages/CompanySignUp"
import Landpage from "pages/Landpage"
import LandpageCompany from "pages/LandpageCompany"
import UserAccount from "pages/UserAccount"
import UserLostPassword from "pages/UserLostPassword"
import UserRentDetail from "pages/UserRentDetail"
import UserRentDone from "pages/UserRentDone"
import UserRequireLostPassword from "pages/UserRequireLostPassword"
import UserSearchVehicleAvailable from "pages/UserSearchVehicleAvailable"
import store from "store"
// Menu Mobile;
import UserAccountMenuMobile from "pages/UserAccount/accountMenuMobile"
import AccountUserBooking from "pages/UserAccount/accountUserBooking"
import AccountUserData from "pages/UserAccount/accountUserData"

//---- withSuspense ----

// User
const UserSignIn = withSuspense(
  lazy(
    () => import(/* webpackChunkName: "page_user_signin" */ "pages/UserSignIn"),
  ),
)
const UserSignUp = withSuspense(lazy(() => import("pages/UserSignUp")))

// Company;
const CompanyDashboard = withSuspensePainel(
  lazy(() => import("pages/CompanyDashboard")),
)

const CompanyBook = withSuspensePainel(lazy(() => import("pages/CompanyBook")))
const CompanyBookAdd = withSuspensePainel(
  lazy(() => import("pages/CompanyBookAdd")),
)
const CompanyBookDetail = withSuspensePainel(
  lazy(() => import("pages/CompanyBookDetail")),
)
const CompanyBookDetailUser = withSuspensePainel(
  lazy(() => import("pages/CompanyBookDetailUser")),
)
const CompanyVehicleGroupAdditional = withSuspensePainel(
  lazy(() => import("pages/CompanyVehicleGroupAdditional")),
)
const CompanyVehicleGroupAdditionalAdd = withSuspensePainel(
  lazy(() => import("pages/CompanyVehicleGroupAdditionalAdd")),
)

const CompanyVehicle = withSuspensePainel(
  lazy(() => import("pages/CompanyVehicle")),
)
const CompanyVehicleAdd = withSuspensePainel(
  lazy(() => import("pages/CompanyVehicleAdd")),
)
const CompanyVehicleGroupDetail = withSuspensePainel(
  lazy(() => import("pages/CompanyVehicleGroupDetail")),
)
const CompanyVehicleGroupManage = withSuspensePainel(
  lazy(() => import("pages/CompanyVehicleGroupManage")),
)
const CompanyVehicleGroupType = withSuspensePainel(
  lazy(() => import("pages/CompanyVehicleGroupType")),
)
const CompanyVehicleGroupTypeAdd = withSuspensePainel(
  lazy(() => import("pages/CompanyVehicleGroupTypeAdd")),
)

const CompanyFinanceVehicle = withSuspensePainel(
  lazy(() => import("pages/CompanyFinanceVehicle")),
)

const CompanyDiscount = withSuspensePainel(
  lazy(() => import("pages/CompanyDiscount")),
)
const CompanyDiscountAdd = withSuspensePainel(
  lazy(() => import("pages/CompanyDiscountAdd")),
)
const CompanyCalendar = withSuspensePainel(
  lazy(() => import("pages/CompanyCalendar")),
)
const CompanyVehicleCalendar = withSuspensePainel(
  lazy(() => import("pages/CompanyVehicleCalendar")),
)

const CompanyClient = withSuspensePainel(
  lazy(() => import("pages/CompanyClient")),
)

const CompanyServices = withSuspensePainel(
  lazy(() => import("pages/CompanyServices")),
)

const CompanyBranch = withSuspensePainel(
  lazy(() => import("pages/CompanyBranch")),
)
const CompanyBranchAdd = withSuspensePainel(
  lazy(() => import("pages/CompanyBranchAdd")),
)
const CompanyBranchManage = withSuspensePainel(
  lazy(() => import("pages/CompanyBranchManage")),
)
const CompanyBranchPickupAdd = withSuspensePainel(
  lazy(() => import("pages/CompanyBranchPickupAdd")),
)
const CompanyBranchDropoffAdd = withSuspensePainel(
  lazy(() => import("pages/CompanyBranchDropoffAdd")),
)

// Admin;
const AdminDashboard = withSuspensePainel(
  lazy(() => import("pages/AdminDashboard")),
)
const AdminUser = withSuspensePainel(lazy(() => import("pages/AdminUser")))
const AdminUserAdd = withSuspensePainel(
  lazy(() => import("pages/AdminUserAdd")),
)
const AdminCompany = withSuspensePainel(
  lazy(() => import("pages/AdminCompany")),
)
const AdminCompanyAdd = withSuspensePainel(
  lazy(() => import("pages/AdminCompanyAdd")),
)
const AdminCompanySimpleAdd = withSuspensePainel(
  lazy(() => import("pages/AdminCompanySimpleAdd")),
)
const AdminVehicle = withSuspensePainel(
  lazy(() => import("pages/AdminVehicle")),
)
const AdminBook = withSuspensePainel(lazy(() => import("pages/AdminBook")))
const AdminCompanyVehicle = withSuspensePainel(
  lazy(() => import("pages/AdminCompanyVehicle")),
)

const GTM_PARAMS = { id: "GTM-TL4QXL4" }

const queryClient = new QueryClient()

function App() {
  return (
    <GTMProvider state={GTM_PARAMS}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ConfigProvider locale={ptBR}>
            <Router history={history}>
              <Switch>
                {/*<!-- User Routes -->*/}
                <Route
                  component={() => <LandpageCompany />}
                  exact
                  path={"/concessionaria"}
                />
                <Route component={() => <Landpage />} exact path={"/"} />

                <Route
                  component={() => <UserAccount />}
                  exact
                  path={"/account/user"}
                />

                <Route
                  exact
                  path={"/signin"}
                  render={(props) => <UserSignIn {...props} />}
                />
                <Route
                  exact
                  path={"/signup"}
                  render={(props) => <UserSignUp {...props} />}
                />

                <Route
                  component={() => <UserLostPassword />}
                  exact
                  path={"/forgotpassword"}
                />
                <Route
                  component={() => <UserRequireLostPassword />}
                  exact
                  path={"/requirepassword"}
                />
                <Route
                  component={() => <UserSearchVehicleAvailable />}
                  exact
                  path={"/pages/vehicle/available"}
                />
                <Route
                  component={() => <UserRentDetail />}
                  exact
                  path={"/pages/vehicle/rent/detail"}
                />
                <Route
                  component={() => <UserRentDone />}
                  exact
                  path={"/pages/vehicle/rent/done"}
                />

                {/*<!-- Mobile Menu Routes -->*/}
                <Route
                  component={() => (
                    <UserAccountMenuMobile title="Minhas reservas">
                      <AccountUserBooking />
                    </UserAccountMenuMobile>
                  )}
                  exact
                  path={"/account/user/mobile/book"}
                />

                <Route
                  component={() => (
                    <UserAccountMenuMobile title="Meus cartões">
                      <CreditCard
                        className="_ml-5 _mr-5"
                        hideSecurityFields={true}
                      />
                    </UserAccountMenuMobile>
                  )}
                  exact
                  path={"/account/user/mobile/creditcard"}
                />

                <Route
                  component={() => (
                    <UserAccountMenuMobile title="Meus dados">
                      <AccountUserData />
                    </UserAccountMenuMobile>
                  )}
                  exact
                  path={"/account/user/mobile/data"}
                />

                {/*<!-- Company Routes -->*/}
                <Route
                  component={() => <CompanySignUp />}
                  exact
                  path={"/signup/company"}
                />
                <Route
                  exact
                  path={"/account/company/dashboard"}
                  render={(props) => <CompanyDashboard {...props} />}
                />

                <Route
                  exact
                  path={"/account/company/book"}
                  render={(props) => <CompanyBook {...props} />}
                />
                <Route
                  exact
                  path={"/account/company/book/add"}
                  render={(props) => <CompanyBookAdd {...props} />}
                />
                <Route
                  exact
                  path={"/account/company/book/detail/:uCode"}
                  render={(props) => <CompanyBookDetail {...props} />}
                />
                <Route
                  exact
                  path={"/account/company/book/detail/:uCode/user"}
                  render={(props) => <CompanyBookDetailUser {...props} />}
                />
                <Route
                  exact
                  path={"/account/company/book/calendar"}
                  render={(props) => <CompanyCalendar {...props} />}
                />
                <Route
                  exact
                  path={"/account/company/book/additional"}
                  render={(props) => (
                    <CompanyVehicleGroupAdditional {...props} />
                  )}
                />
                <Route
                  exact
                  path={"/account/company/book/additional/add"}
                  render={(props) => (
                    <CompanyVehicleGroupAdditionalAdd {...props} />
                  )}
                />
                <Route
                  exact
                  path={"/account/company/book/additional/edit/:uCode"}
                  render={(props) => (
                    <CompanyVehicleGroupAdditionalAdd {...props} />
                  )}
                />

                <Route
                  exact
                  path={"/account/company/vehicle"}
                  render={(props) => <CompanyVehicle {...props} />}
                />
                <Route
                  exact
                  path={"/account/company/vehicle/add"}
                  render={(props) => <CompanyVehicleAdd {...props} />}
                />
                <Route
                  exact
                  path={"/account/company/vehicle/edit/:uCode"}
                  render={(props) => <CompanyVehicleAdd {...props} />}
                />
                <Route
                  exact
                  path={"/account/company/vehicle/group/edit/:uCode"}
                  render={(props) => <CompanyVehicleAdd {...props} />}
                />
                <Route
                  exact
                  path={"/account/company/vehicle/group/detail/:uCode"}
                  render={(props) => <CompanyVehicleGroupDetail {...props} />}
                />
                <Route
                  exact
                  path={"/account/company/vehicle/calendar"}
                  render={(props) => <CompanyVehicleCalendar {...props} />}
                />
                <Route
                  exact
                  path={"/account/company/vehicle/manage/:uCode"}
                  render={(props) => <CompanyVehicleGroupManage {...props} />}
                />
                <Route
                  exact
                  path={"/account/company/vehicle/type"}
                  render={(props) => <CompanyVehicleGroupType {...props} />}
                />
                <Route
                  exact
                  path={"/account/company/vehicle/type/add"}
                  render={(props) => <CompanyVehicleGroupTypeAdd {...props} />}
                />
                <Route
                  exact
                  path={"/account/company/vehicle/type/edit/:uCode"}
                  render={(props) => <CompanyVehicleGroupTypeAdd {...props} />}
                />

                <Route
                  exact
                  path={"/account/company/vehicle/finance"}
                  render={(props) => <CompanyFinanceVehicle {...props} />}
                />

                <Route
                  exact
                  path={"/account/company/user"}
                  render={(props) => <AdminUser {...props} />}
                />
                <Route
                  exact
                  path={"/account/company/user/add"}
                  render={(props) => <AdminUserAdd {...props} />}
                />
                <Route
                  exact
                  path={"/account/company/user/edit/:uCode"}
                  render={(props) => <AdminUserAdd {...props} />}
                />

                <Route
                  exact
                  path={"/account/company/discount"}
                  render={(props) => <CompanyDiscount {...props} />}
                />
                <Route
                  exact
                  path={"/account/company/discount/add"}
                  render={(props) => <CompanyDiscountAdd {...props} />}
                />
                <Route
                  exact
                  path={"/account/company/discount/edit/:uCode"}
                  render={(props) => <CompanyDiscountAdd {...props} />}
                />

                <Route
                  exact
                  path={"/account/company/branch"}
                  render={(props) => <CompanyBranch {...props} />}
                />
                <Route
                  exact
                  path={"/account/company/branch/add"}
                  render={(props) => <CompanyBranchAdd {...props} />}
                />
                <Route
                  exact
                  path={"/account/company/branch/edit/:uCode"}
                  render={(props) => <CompanyBranchAdd {...props} />}
                />
                <Route
                  exact
                  path={"/account/company/branch/manage/:uCode"}
                  render={(props) => <CompanyBranchManage {...props} />}
                />
                <Route
                  exact
                  path={
                    "/account/company/branch/pickup/add/:uCodeCompanyBranch"
                  }
                  render={(props) => <CompanyBranchPickupAdd {...props} />}
                />
                <Route
                  exact
                  path={
                    "/account/company/branch/pickup/edit/:uCodeCompanyBranch/:uCodePickup"
                  }
                  render={(props) => <CompanyBranchPickupAdd {...props} />}
                />
                <Route
                  exact
                  path={
                    "/account/company/branch/dropoff/add/:uCodeCompanyBranch"
                  }
                  render={(props) => <CompanyBranchDropoffAdd {...props} />}
                />
                <Route
                  exact
                  path={
                    "/account/company/branch/dropoff/edit/:uCodeCompanyBranch/:uCodeDropoff"
                  }
                  render={(props) => <CompanyBranchDropoffAdd {...props} />}
                />

                <Route
                  exact
                  path={"/account/company/client"}
                  render={(props) => <CompanyClient {...props} />}
                />

                <Route
                  exact
                  path={"/account/company/services"}
                  render={(props) => <CompanyServices {...props} />}
                />

                <Route
                  exact
                  path={"/account/company/config/:uCode"}
                  render={(props) => <AdminCompanyAdd {...props} />}
                />

                {/*<!-- Admin Routes -->*/}
                <Route
                  exact
                  path={"/account/admin/dashboard"}
                  render={(props) => <AdminDashboard {...props} />}
                />
                <Route
                  exact
                  path={"/account/admin/book"}
                  render={(props) => <AdminBook {...props} />}
                />
                <Route
                  exact
                  path={"/account/admin/user"}
                  render={(props) => <AdminUser {...props} />}
                />
                <Route
                  exact
                  path={"/account/admin/company"}
                  render={(props) => <AdminCompany {...props} />}
                />
                <Route
                  exact
                  path={"/account/admin/company/add"}
                  render={(props) => <AdminCompanySimpleAdd {...props} />}
                />
                <Route
                  exact
                  path={"/account/admin/company/edit/:uCode"}
                  render={(props) => <AdminCompanySimpleAdd {...props} />}
                />
                <Route
                  exact
                  path={"/account/admin/vehicle"}
                  render={(props) => <AdminVehicle {...props} />}
                />
                <Route
                  exact
                  path={"/account/admin/company/:uCode/vehicle"}
                  render={(props) => <AdminCompanyVehicle {...props} />}
                />
                <Route
                  exact
                  path={
                    "/account/admin/company/:uCodeCompany/vehicle/group/add"
                  }
                  render={(props) => <CompanyVehicleAdd {...props} />}
                />
                <Route
                  exact
                  path={
                    "/account/admin/company/:uCodeCompany/vehicle/group/edit/:uCode"
                  }
                  render={(props) => <CompanyVehicleAdd {...props} />}
                />
              </Switch>
            </Router>
          </ConfigProvider>
        </Provider>
      </QueryClientProvider>
    </GTMProvider>
  )
}

export default App
