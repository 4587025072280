import { StateMachineType } from "libs/statemachine"
import ActionType from "store/getter/ActionType"
import baseReducer from "store/utilities/baseReducer"

export interface IGetterState {
  readonly data: { [key: string]: any | null }
  readonly viewState: { [key: string]: StateMachineType }
}

const initialState: IGetterState = {
  data: {},
  viewState: {},
}

const getterReducer = baseReducer(initialState, {
  [ActionType.GETTER_DATA](state, action) {
    return {
      ...state,
      data: {
        ...state.data,
        [action.payload.feature]: action.payload.data,
      },
    }
  },
  [ActionType.GETTER_VIEW_STATE](state, action) {
    return {
      ...state,
      viewState: {
        ...state.viewState,
        [action.payload.feature]: action.payload.viewState,
      },
    }
  },
})

export default getterReducer
