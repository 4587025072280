import React from "react"

import { Steps } from "antd"

import useDeviceDetection from "@/hooks/useDeviceDetection"

export const BookingSteps = ({ current }: { current: number }) => {
  const device = useDeviceDetection()

  const steps = [
    {
      title: device !== "mobile" ? "Local e data" : "",
    },
    {
      title: device !== "mobile" ? "Escolha do grupo de carros" : "",
    },
    {
      title: device !== "mobile" ? "Proteções e adicionais" : "",
    },
  ]

  return (
    <Steps
      current={current}
      items={steps}
      responsive={false}
      size={device === "mobile" ? "small" : "default"}
    />
  )
}
