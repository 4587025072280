import queryString from 'query-string'

type QueryParams = {
  [key: string]: any
}

class ManageUrl {
  static mountQueryParams(params: QueryParams) {
    return queryString.stringify(params)
  }
  static parseQueryParams(query: string) {
    return queryString.parse(query)
  }
}

export default ManageUrl