import React from 'react'
import { Form, Input } from 'antd'
import Text from 'components/atom/text'

interface FormInputMoneyProps {
  label: string;
  name: string;
  required?: boolean;
  min?: number;
  max?: number;
  disabled?: boolean;
  extra?: any;
}

const FormInputMoney: React.FC<FormInputMoneyProps> = (props) => {
  const rules = []

  if (props?.required) {
    rules.push({ required: true, message: 'Este campo não pode ser vazio.' })
  }

  if (props?.min) {
    rules.push({ min: props?.min, message: `Este campo deve ter no mínimo ${props?.min} letras.`})
  }

  if (props?.max) {
    rules.push({ max: props?.max, message: `Este campo deve ter no máximo ${props?.max} letras.`})
  }

  return (
    <div>
      <Text className="_text-2 _font">{props.label}{props.required && ' *'}</Text>
      
      <Form.Item
          name={props.name}
          hasFeedback
          rules={rules}
          extra={props.extra}
      >
        <Input prefix="R$" size="large" disabled={props?.disabled || false} />
      </Form.Item>
    </div>
  )
}

export default FormInputMoney