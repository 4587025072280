import { useState, useEffect } from "react"

const get = (key: string, json = false) => {
  try {
    const val = localStorage.getItem(key)

    if (val && json) {
      return JSON.parse(val)
    }

    return val
  } catch (e) {
    return null
  }
}

const set = (key: string, data: any, json = false) => {
  try {
    if (json) {
      return localStorage.setItem(key, JSON.stringify(data))
    }

    return localStorage.setItem(key, String(data))
  } catch (e) {
    return null
  }
}

const remove = (key: string) => {
  try {
    return localStorage.removeItem(key)
  } catch (e) {
    return null
  }
}

const ALIAS_KEY_STORAGE = "@53_V1"

const Keys = {
  Authorization: `${ALIAS_KEY_STORAGE}:oauth:authorization`,
  Cookie: `${ALIAS_KEY_STORAGE}:cookies:accept`,
}

/**
 * Responsável pelo consumo dos dados guardados em storage;
 *
 * GET: Quando chamado sem parametros irá retornar os dados salvos para chave específica;
 * SET: Quando chamado com parâmetros irá adicionar ou atualizar os dados;
 */
export default class Storage {
  // @object
  static authorization = (data?: string) => {
    if (typeof data !== "undefined") {
      return set(Keys.Authorization, data, true)
    } else {
      return get(Keys.Authorization, true)
    }
  }

  static hasAuthorization = () => {
    return get(Keys.Authorization, true) !== null
  }

  // @boolean
  static cookie = (accept: boolean) => {
    return set(Keys.Cookie, { cookie: accept }, true)
  }

  static hasCookieAccept = () => {
    return get(Keys.Cookie, true) !== null
  }

  static clear = () => {
    remove(Keys.Authorization)
  }
}

export function useStorage(): { authorization: any } {
  const [authorization, setAuthorization] = useState(null)

  useEffect(() => {
    setAuthorization(Storage.authorization())
  }, [])

  return { authorization }
}
