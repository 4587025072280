import React from "react"

import Text from "components/atom/text"

import "./styles.scss"

function Footer() {
  return (
    <div className="footer-content-base _pt-6 _pb-6">
      <div className="_mb-6 _text-center">
        <Text className="_text-4">Atendimento ao cliente</Text>
      </div>

      <div className="_text-center">
        <div>
          <a
            href="https://closecar-public.s3.amazonaws.com/Politica+de+Privacidade+da+CloseCar.pdf"
            rel="noreferrer"
            target="_blank"
          >
            Politica de Privacidade
          </a>
          <br />
          <a
            href="https://closecar-public.s3.amazonaws.com/Termos+de+Uso+-+Usuario+e+CloseCar.pdf"
            rel="noreferrer"
            target="_blank"
          >
            Termos de uso do usuário
          </a>
          <br />
          <br />
          <div className="_pl-8 _pr-8">
            Para mais informações consulte nossos termos de uso e politica de
            privacidade.
          </div>
          <br />
          <div>
            Caso queira entre em contato através do e-mail
            atendimento@closecar.com.br
          </div>
          <br />
        </div>
      </div>

      {/* <div className="_mb-6 _mt-6 _text-center">
          <Text className="_text-4">Formas de Pagamento</Text>
      </div>

      <div className="_mb-6 _text-center">
        <img alt="" src={visa} className="_mr-1" />
      </div> */}

      <div className="text-center">
        <Text className="text-xs">
          A Closecar faz parte da Easy APP S.A, que é uma sociedade anônima
          registrada na República Federativa do Brasil, cuja sede social está em
          Belo Horizonte - MG.
        </Text>
        <br />
        <Text className="text-xs">
          © 2024 closecar.com.br todos os direitos reservados.
        </Text>
        <Text className="text-xs">
          {process.env.REACT_APP_ENV === "production"
            ? "♥"
            : process.env.REACT_APP_ENV === "beta"
              ? "♠"
              : "♦"}
        </Text>
      </div>

      {/* <div className="landpage-subscription_whatsapp">
        <a href="https://wa.me/5531984330552" target="noopener noreferrer _blank">
          <ImageLoad url="https://53cars.s3.sa-east-1.amazonaws.com/whatsapp.png" width={64} />
        </a>
      </div> */}
    </div>
  )
}

export default Footer
