import React, { useEffect, useState } from "react"

import "./styles.scss"

type Props = {
  url: string
  className?: string
  ableToOpen?: boolean
  title?: string
  alt?: string
}

const Image = ({ ableToOpen, alt, className, title, url }: Props) => {
  const [imageUrl, setImageUrl] = useState(url)

  const isDocument = imageUrl.indexOf(".pdf") >= 0

  useEffect(() => {
    setImageUrl(url)
  }, [url])

  const openImageNewWindow = () => {
    if (!ableToOpen) {
      return false
    }

    const checkPdf = imageUrl.indexOf(".pdf") >= 0 || url.indexOf(".pdf") >= 0

    if (checkPdf) {
      window.open(url, "_blank")
    } else {
      window.open(imageUrl, "_blank")
    }
  }

  return (
    <div
      className={`${ableToOpen && "_cursor_pointer"} image_base_container`}
      onClick={openImageNewWindow}
    >
      {isDocument ? (
        <div>
          <iframe
            height="256px"
            src={imageUrl}
            title="Documents"
            width="100%"
          />
        </div>
      ) : (
        <img
          alt={alt}
          className={className}
          onError={() =>
            setImageUrl(
              "https://53cars.s3-sa-east-1.amazonaws.com/no-camera.png",
            )
          }
          src={imageUrl}
          style={{ display: "block", height: "auto", maxWidth: "100%" }}
        />
      )}

      {title && (
        <div className="_text-center _text-3 _font-weight-bold _mt-2">
          {" "}
          {title}{" "}
        </div>
      )}

      {ableToOpen && url && (
        <div className="_text-center _text-1 _mt-2"> Clique para abrir </div>
      )}
    </div>
  )
}

export default Image
