import React from "react"

const FooterRequirements = () => {
  return (
    <div>
      <div className="bg-slate-950 text-white pt-8 pb-6 pl-6 pr-6">
        <div className="md:flex">
          <div className="flex justify-center md:block">
            <img
              className="h-10"
              src="https://53cars.s3-sa-east-1.amazonaws.com/logo_principal_white.png"
            />
          </div>

          <div className="flex items-center max-sm:mt-4 md:ml-4">
            <div className="text-center text-lg">
              Nunca foi tão fácil alugar um carro!
            </div>
          </div>
        </div>

        <div className="mt-12 mb-12 text-2xl">
          Veja alguns do requisitos para iniciar sua locação
        </div>

        <div className="mb-12 grid md:grid-cols-4">
          <div className="mb-6 md:mb-0 md:border-r-2 md:pr-2 md:mr-2">
            <div className="mb-2 text-xl font-bold">Idade</div>
            <div className="text-sm">
              Ter entre 21 e 70 anos de idade. Caso você tem menos de 25 ou mais
              que 70 anos, você poderá ser obrigado a pagar uma taxa adicional.
            </div>
          </div>

          <div className="mb-6 md:mb-0 md:border-r-2 md:pl-4 md:pr-2 md:mr-2">
            <div className="mb-2 text-xl font-bold">CNH</div>
            <div className="text-sm">
              CNH definitiva dentro do prazo de vencimento, emitida há mais de 2
              anos.
            </div>
          </div>

          <div className="mb-6 md:mb-0 md:border-r-2 md:pl-4 md:pr-2 md:mr-2">
            <div className="mb-2 text-xl font-bold">Documentos</div>
            <div className="text-sm">
              RG e CPF originais e comprovante de endereço. Não ter restrições
              de qualquer espécie junto aos órgãos de proteção ao crédito no
              momento da contratação. Estrangeiros Passaporte original e válido
            </div>
          </div>

          <div className="md:pl-4">
            <div className="mb-2 text-xl font-bold">Cartão de crédito</div>
            <div className="text-sm">
              Cartão de crédito válido, de sua titularidade, dentro do prazo de
              vencimento, emitido por uma instituição bancária e com limite de
              crédito disponível para pré-autorização da caução de garantia.
            </div>
          </div>
        </div>
        <div className="text-base">
          * Condições sujeitas à alteração de acordo com a locadora.
        </div>
      </div>
    </div>
  )
}

export default FooterRequirements
