import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { Avatar } from "antd"
import Config from "libs/config"
import { useStorage } from "libs/storage"

import { useChecktokenStore } from "@/store/checktoken/useChecktokenStore"

const Header: React.FC<{ withLogo?: boolean; withMenu?: boolean }> = ({
  withLogo = true,
  withMenu = true,
}) => {
  const { t } = useTranslation()
  const { authorization } = useStorage()
  const history = useHistory()
  const { executeCheckToken } = useChecktokenStore()

  useEffect(() => {
    executeCheckToken()
  }, [])

  const goHome = () => {
    history.push("/")
  }

  const goAccountOrLogin = () => {
    let url = "/account/user"

    switch (authorization?.role) {
      case Config.role.admin:
        url = "/account/admin/dashboard"
        break
      case Config.role.user:
        url = "/account/user"
        break
      case Config.role.company:
      case Config.role.companyOperator:
      case Config.role.companyBranch:
      case Config.role.companyBranchOperator:
        url = "/account/company/dashboard"
        break
    }

    history.push(authorization ? url : "/signin")
  }

  const [name] = authorization?.name || "U"

  return (
    <div className={`py-4 grid grid-cols-2 backdrop-blur-md bg-white/30`}>
      <div>
        {withLogo && (
          <div className="ml-6 flex items-center h-full" onClick={goHome}>
            <img
              className="h-7 md:h-10"
              src="https://53cars.s3-sa-east-1.amazonaws.com/logo_principal.png"
            />
          </div>
        )}
      </div>

      <div className="ml-4 mr-6 flex justify-end">
        {withMenu && (
          <>
            <div
              className="px-4 py-2 bg-white cursor-pointer drop-shadow-lg rounded-full"
              onClick={goAccountOrLogin}
            >
              {authorization ? (
                <div className="flex items-center">
                  <div>
                    <Avatar
                      className="mr-2"
                      size="small"
                      style={{ backgroundColor: "#fde3cf", color: "#f56a00" }}
                    >
                      {name}
                    </Avatar>
                  </div>
                  <div>
                    <div className="text-sm">
                      <span className="overflow-hidden text-ellipsis line-clamp-1">
                        {authorization?.name}
                      </span>
                    </div>
                    <div className="text-xs">{t("my_account")}</div>
                  </div>
                </div>
              ) : (
                t("login")
              )}
            </div>
            {!authorization && (
              <div
                className="hidden md:block px-4 py-2 ml-4 cursor-pointer drop-shadow-md bg-white rounded-full"
                onClick={() => history.push("/signup")}
              >
                Cadastre-se
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Header
