import React from "react"

import ImageLoad from "components/atom/image_load"
import Text from "components/atom/text"

import { ReactComponent as CarDoor } from "./images/car-door.svg"
import { ReactComponent as Shift } from "./images/shift.svg"
import { ReactComponent as Snowflake } from "./images/snowflake.svg"

const DescriptionVehicle: React.FC<{ className?: string; vehicle: any }> = ({
  className,
  vehicle,
}) => {
  return (
    <div
      className={`flex gap-2 flex-wrap justify-center md:justify-start ${className}`}
    >
      {vehicle?.doors && (
        <div className="p-1 flex items-center bg-slate-50 rounded-md border-slate-100 border-2">
          <CarDoor className="h-[14px] w-[14px]" />
          <Text className="text-xs ml-2">{`${vehicle?.doors} portas`}</Text>
        </div>
      )}

      {vehicle?.airConditioning && (
        <div className="p-1 flex items-center bg-slate-50 rounded-md border-slate-100 border-2">
          <Snowflake className="h-[14px] w-[14px]" />
          <Text className="text-xs ml-2">A/C</Text>
        </div>
      )}

      {vehicle?.abs && (
        <div className="p-1 flex items-center bg-slate-50 rounded-md border-slate-100 border-2">
          <ImageLoad
            className="h-[14px] w-[14px]"
            url="https://closecar-public.s3.amazonaws.com/abs.png"
          />
          <Text className="text-xs ml-2">ABS</Text>
        </div>
      )}

      <div className="p-1 flex items-center bg-slate-50 rounded-md border-slate-100 border-2">
        <Shift className="h-[14px] w-[14px]" />
        <Text className="text-xs ml-2">{vehicle?.carGearbox}</Text>
      </div>
    </div>
  )
}

export default DescriptionVehicle
