import React from "react"

import type { RadioChangeEvent } from "antd"

import { Form, Radio, Space } from "antd"

interface FormRadioGroupProps {
  direction?: "horizontal" | "vertical"
  disabled?: boolean
  name: string
  onChange?: (e: RadioChangeEvent) => void
  options: Array<{ value: any; label: string; description?: string }>
  radioBoxClassName?: string
  radioDescriptionClassName?: string
  radioLabelClassName?: string
  required?: boolean
  requiredMessage?: string
  wrap?: boolean
}

const FormRadioGroup: React.FC<FormRadioGroupProps> = (props) => {
  const rules = []

  if (props?.required) {
    rules.push({
      message: props?.requiredMessage
        ? props.requiredMessage
        : "Este campo não pode ser vazio.",
      required: true,
    })
  }

  return (
    <div>
      <Form.Item name={props.name} rules={rules}>
        <Radio.Group
          className={props.direction === "vertical" ? "w-full" : ""}
          onChange={props.onChange || undefined}
        >
          <Space
            className={props.direction === "vertical" ? "w-full" : ""}
            direction={props.direction || "horizontal"}
            wrap={props.wrap || true}
          >
            {props?.options?.map((option: any) => (
              <div className={props.radioBoxClassName} key={option.value}>
                <Radio disabled={props.disabled || false} value={option.value}>
                  <div className={props.radioLabelClassName}>
                    {option.label}
                  </div>
                  <div className={props.radioDescriptionClassName}>
                    {option.description}
                  </div>
                </Radio>
              </div>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
    </div>
  )
}

export default FormRadioGroup
