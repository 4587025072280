import { Dispatch } from "redux"

import FetchApi from "@/libs/fetch"
import { mappedApis } from "@/libs/fetch/apis"
import { ErrorPayload } from "@/libs/fetch/interface"
import Storage from "@/libs/storage"
import {
  RequestPassword,
  ChangePassword,
  SigninParams,
} from "@/store/signin/Action"
import ActionType from "@/store/signin/ActionType"
import * as ActionUtility from "@/store/utilities/actionUtility"

export const signin = async ({ email, password }: SigninParams) => {
  const fetch = await FetchApi.post({
    params: { email, password },
    url: mappedApis.auth.signin.post.url,
  })

  if (
    fetch.status === FetchApi.HttpSuccessfulOk ||
    fetch.status === FetchApi.HttpSuccessfulCreated
  ) {
    Storage.authorization(fetch.data)
  }

  return {
    data: fetch.data,
    hasError: FetchApi.hasError(fetch.status),
  }
}

export const requestPassword = async ({ email }: RequestPassword) => {
  const fetch = await FetchApi.post({
    params: { email },
    url: mappedApis.auth.forgotPassword.post.url,
  })

  return {
    data: fetch.data,
    hasError: FetchApi.hasError(fetch.status),
  }
}

export const changePassword = async ({ password, token }: ChangePassword) => {
  const fetch = await FetchApi.put({
    params: {
      password,
      token,
    },
    url: mappedApis.auth.changePassword.put.url,
  })

  return {
    data: fetch.data,
    hasError: FetchApi.hasError(fetch.status),
  }
}

export const setLoading = (dispatch: Dispatch, payload: boolean | null) =>
  dispatch(ActionUtility.createAction(ActionType.SIGNIN_LOADING, payload))

export const setSuccess = (dispatch: Dispatch, payload: boolean | null) =>
  dispatch(ActionUtility.createAction(ActionType.SIGNIN_SUCCESS, payload))

export const setError = (dispatch: Dispatch, payload: ErrorPayload | null) =>
  dispatch(ActionUtility.createAction(ActionType.SIGNIN_ERROR, payload))

export const setUser = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.SIGNIN_USER, payload))
