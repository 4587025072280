import { configureStore } from "@reduxjs/toolkit"

import accountReducer from "./account/accountSlice"
import checkTokenReducer from "./checktoken/checktokenSlice"
import creditcard, { ICreditCardState } from "./creditcard/Reducer"
import document, { IDocumentState } from "./document/Reducer"
import executer, { IExecuterState } from "./executer/Reducer"
import form, { IFormState } from "./form/Reducer"
import getter, { IGetterState } from "./getter/Reducer"
import signin, { ISigninState } from "./signin/Reducer"
import signup, { ISignupState } from "./signup/Reducer"
import table, { ITableState } from "./table/Reducer"

export interface AppState {
  creditcard?: ICreditCardState
  document?: IDocumentState
  executer?: IExecuterState
  form?: IFormState
  getter?: IGetterState
  signin?: ISigninState
  signup?: ISignupState
  table?: ITableState
}

const store = configureStore({
  reducer: {
    account: accountReducer,
    checkToken: checkTokenReducer,
    creditcard,
    document,
    executer,
    form,
    getter,
    signin,
    signup,
    table,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export default store
