import React from "react"
import { useHistory } from "react-router-dom"

import LayoutAdmin from "components/organism/layout_admin"
import LayoutCompany from "components/organism/layout_company"

const LayoutDynamic: React.FC<any> = (props) => {
  const history = useHistory()
  const isAdmin = history.location.pathname.indexOf("admin") >= 0
  const isCompany = history.location.pathname.indexOf("company") >= 0

  // Fix: When the path contain both;
  if (isCompany && isAdmin) {
    return <LayoutAdmin>{props.children}</LayoutAdmin>
  }

  if (isCompany) {
    return <LayoutCompany>{props.children}</LayoutCompany>
  }

  return <LayoutAdmin>{props.children}</LayoutAdmin>
}

export default LayoutDynamic
