import { StateMachineType } from "libs/statemachine"
import { createSelector } from "reselect"
import { AppState } from "store"
import { IGetterState } from "store/getter/Reducer"

export interface GetterSelector {
  data: { [key: string]: any | null }
  viewState: { [key: string]: StateMachineType }
}

function getterData(getter: IGetterState): GetterSelector {
  return { data: getter.data, viewState: getter.viewState }
}

export const getterSelector = createSelector<AppState, any>(
  (state) => state?.getter,
  getterData,
)
