import Config, { Environment } from "@/libs/config"

const apis: Record<string, string> = {
  [Environment.Development]: "http://localhost:3014",
  [Environment.Homolog]: "https://dev-api.closecar.com.br/auth",
  [Environment.Production]: "https://api.closecar.com.br/auth",
}

export const authApi = () => {
  const enviroment = Config.environment.auth

  return apis[enviroment]
}
