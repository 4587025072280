import React, { Suspense } from "react"

import Image from "components/atom/image"

const ImageLoad = (props: any) => {
  const url = props.url || ""

  return (
    <div
      style={{ height: props.height || "auto", width: props.width || "auto" }}
    >
      <Suspense
        fallback={<div className="_text-center">Carregando a imagem...</div>}
      >
        <Image
          ableToOpen={props.ableToOpen}
          alt={props?.alt}
          className={props.className}
          title={props.title}
          url={url}
        />
      </Suspense>
    </div>
  )
}

export default ImageLoad
