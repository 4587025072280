import React from 'react'

import { Card } from 'antd'
import Text from 'components/atom/text'

import { ReactComponent as EmptyBookingSvg } from './assets/empty_booking.svg'

import './index.scss'

const EmptyBooking = () => {
  return (
    <Card className="empty-favorite-content">
      <div>
        <Text className="_text-6">Você ainda não fez nenhuma reserva. </Text>
        <br />
        <Text className="_text-3">Busque agora e reserve um carro! </Text>
      </div>
      <div className='flex justify-center mt-6'>
        <EmptyBookingSvg className="empty-favorite-img" />
      </div>
    </Card>
  )
}

export default EmptyBooking