import { Buffer } from "buffer"
import FetchApi from "libs/fetch"
import { Dispatch } from "redux"
import * as ActionUtility from "store/utilities/actionUtility"

import { mappedApis } from "@/libs/fetch/apis"

import ActionType from "./ActionType"

interface UploadParams {
  file: any
  type: string
}

export const upload = async ({ file, type }: UploadParams) => {
  const fetch = await FetchApi.post({
    hasAuth: true,
    params: { fileType: type },
    url: mappedApis.base.document.signed.post.url,
  })

  const upload = await FetchApi.upload(fetch.data.signedRequest, type, file)

  const document = await FetchApi.get({
    hasAuth: true,
    url: `${mappedApis.base.document.signed.post.url}/${fetch.data.fileKey}`, // Fix this;
  })

  return {
    fileKey: fetch.data.fileKey,
    hasError: upload.status === FetchApi.HttpErrorBadRequest,
    url: document.data.signedUrl,
  }
}

export const uploadBase64 = async ({ file }: { file: string }) => {
  const type = file.split(";")[0].split("/")[1]

  const fetch = await FetchApi.post({
    hasAuth: true,
    params: { fileType: `image/${type}` },
    url: mappedApis.base.document.signed.post.url,
  })

  const buffer = Buffer.from(
    file.replace(/^data:image\/\w+;base64,/, ""),
    "base64",
  )

  const upload = await FetchApi.uploadBase64(
    fetch.data.signedRequest,
    type,
    buffer,
  )

  const document = await FetchApi.get({
    hasAuth: true,
    url: `${mappedApis.base.document.signed.post.url}/${fetch.data.fileKey}`, // Fix this;
  })

  return {
    fileKey: fetch.data.fileKey,
    hasError: upload.status === FetchApi.HttpErrorBadRequest,
    url: document.data.signedUrl,
  }
}

export const setLoading = (dispatch: Dispatch, payload: boolean) =>
  dispatch(ActionUtility.createAction(ActionType.DOCUMENT_LOADING, payload))

export const setSuccess = (dispatch: Dispatch, payload: boolean) =>
  dispatch(ActionUtility.createAction(ActionType.DOCUMENT_SUCCESS, payload))

export const setError = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.DOCUMENT_ERROR, payload))

export const setData = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.DOCUMENT_DATA, payload))
