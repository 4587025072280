import { initReactI18next } from "react-i18next"

import i18n from "i18next"
import ICU from "i18next-icu"

import { en } from "./en"
import { es } from "./es"
import { pt } from "./pt"

const resources = {
  en,
  es,
  pt,
}

i18n
  .use(initReactI18next)
  .use(ICU)
  .init({
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    keySeparator: false, // we do not use keys in form messages.welcome
    lng: "pt",
    parseMissingKeyHandler: () => {
      return "Ops! Acorreu um erro inesperado, tente novamente ou entre em contato com o suporte."
    },
    resources,
  })

export default i18n
