import FetchApi from "libs/fetch"
import { StateMachineType } from "libs/statemachine"
import { Dispatch } from "redux"
import ActionType from "store/getter/ActionType"
import * as ActionUtility from "store/utilities/actionUtility"

export const getData = async ({
  signal,
  url,
}: {
  url: string
  signal?: AbortSignal
}) => {
  const fetch = await FetchApi.get({ hasAuth: true, options: { signal }, url })
  return {
    data: fetch.data,
    hasError: FetchApi.hasError(fetch.status),
  }
}

export const setData = (dispatch: Dispatch, payload: PayloadDataGetter) =>
  dispatch(ActionUtility.createAction(ActionType.GETTER_DATA, payload))

export const setViewState = (
  dispatch: Dispatch,
  payload: PayloadViewStateGetter,
) => dispatch(ActionUtility.createAction(ActionType.GETTER_VIEW_STATE, payload))

interface PayloadDataGetter {
  data: any | null
  feature: string
}

interface PayloadViewStateGetter {
  feature: string
  viewState: StateMachineType
}
