import React from "react"

import { Text } from "@/components/atom"

const Title: React.FC<any> = (props) => {
  return (
    <div>
      <Text className="_text-10 _font-weight-bold">{props.children}</Text>
    </div>
  )
}

export default Title
