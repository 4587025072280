import React, { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"

import { Skeleton } from "antd"
import EmptyVehicle from "components/molecule/empty/vehicle"
import SearchForm, { SearchQueryParams } from "components/molecule/search/form"
import SkeletonList from "components/molecule/skeleton_list"
import Footer from "components/organism/footer"
import Header from "components/organism/header"
import { logEvent } from "firebase/analytics"
import ManageDate from "libs/date"
import { analytics } from "libs/firebase"
import StateMachine from "libs/statemachine"
import ManageUrl from "libs/url"
import { FeatureGetter } from "store/getter/Action"
import { useGetterStore } from "store/getter/Hooks"

import { PlaceSelected } from "@/components/molecule/search"
import { useChecktokenStore } from "@/store/checktoken/useChecktokenStore"

import CardGroupVehicle from "./components/cardGroup"
import { BookingSteps } from "./components/steps"
import { VehicleSearched } from "./types"

export interface QuerySearch {
  dropoff?: PlaceSelected
  pickup?: PlaceSelected
}

const UserSearchVehicleAvailable = () => {
  const history = useHistory()
  const location = useLocation()
  const { executeCheckToken, viewState: viewStateAuth } = useChecktokenStore()

  const params: SearchQueryParams =
    ManageUrl.parseQueryParams(location.search) || {}

  const [query, setQuery] = useState<QuerySearch>({})

  const {
    data: dataVehicle = [],
    getData: getVehicleAvailable,
    viewState,
  } = useGetterStore({
    feature: FeatureGetter.GetVehicleAvailable,
  })

  const { getData: getDataPickupDropoff } = useGetterStore({
    feature: FeatureGetter.GetPlacesPickupDropoff,
  })

  const getPickupDropoffPlace = async () => {
    const dataPickup = await getDataPickupDropoff({
      queryParams: { uCode: params.pickup },
    })

    setQuery((prevState: any) => ({ ...prevState, pickup: dataPickup }))

    if (params.dropoff && params.pickup !== params.dropoff) {
      const dataDropoff = await getDataPickupDropoff({
        queryParams: { uCode: params.dropoff },
      })
      setQuery((prevState: any) => ({ ...prevState, dropoff: dataDropoff }))
    } else {
      setQuery((prevState: any) => ({ ...prevState, dropoff: dataPickup }))
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    executeCheckToken()
    getPickupDropoffPlace()
    getVehicleAvailable({
      queryParams: {
        dropoff: params.dropoff,
        end: params.end,
        pickup: params.pickup,
        start: params.start,
      },
    })
  }, [])

  const onSelectVehicle = (vehicle: VehicleSearched | undefined) => {
    if (StateMachine.isError(viewStateAuth)) {
      const result = ManageUrl.mountQueryParams({
        dropoff: params.dropoff,
        end: params.end,
        pickup: params.pickup,
        start: params.start,
      })

      history.push("/signin", {
        dataToRedirect: vehicle,
        messageToRedirect:
          "Para concluir o seu aluguel você deve logar primeiro.",
        redirect: true,
        urlToRedirect: `/pages/vehicle/available?${result}`,
      })
    } else {
      logEvent(analytics, `vehicle_group_type`, {
        extras: {
          branchName: vehicle?.company.companyName,
          description: vehicle?.description,
        },
        type: vehicle?.vehicleGroup,
      })

      history.push(`/pages/vehicle/rent/detail`, {
        dropoff: params.dropoff,
        end: params.end,
        pickup: params.pickup,
        start: params.start,
        vehicle,
      })
    }
  }

  const onSubmit = (data: any) => {
    const result = ManageUrl.mountQueryParams({
      dropoff: data.dropoff?.uCode,
      dropoffPlace: data.pickup?.place,
      end: data.end,
      pickup: data.pickup?.uCode,
      pickupPlace: data.pickup?.place,
      start: data.start,
    })

    history.push(`/pages/vehicle/available?${result}`)
    window.location.reload()
  }

  return (
    <div>
      <Header />
      <div className="px-4 bg-slate-200 flex justify-center">
        <div className="max-w-[1280px] w-full">
          <div className="mt-4 bg-white border-slate-100 border-2">
            <details>
              <summary className="list-none">
                <div className="w-full cursor-pointer">
                  {!query.pickup || !query.dropoff ? (
                    <Skeleton active className="p-4" paragraph={false} title />
                  ) : (
                    <div className="w-full p-4">
                      <div>
                        <div>
                          <span className="overflow-hidden text-ellipsis line-clamp-1">
                            Retirada em&nbsp;
                            <span className="font-bold">
                              {query.pickup.place}
                            </span>
                            &nbsp;no dia&nbsp;
                            <span className="font-bold">
                              {ManageDate.friendlyFormat(params.start)}
                            </span>
                          </span>
                        </div>
                        <div>
                          <span className="overflow-hidden text-ellipsis line-clamp-1">
                            Devolução em&nbsp;
                            <span className="font-bold">
                              {query.dropoff.place}
                            </span>
                            &nbsp;no dia&nbsp;
                            <span className="font-bold">
                              {ManageDate.friendlyFormat(params.end)}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div>
                        <span className="text-xs underline underline-offset-4 font-bold text-amber-700">
                          Editar
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </summary>
              <p className="p-4 border-t-2 border-t-slate-200">
                <SearchForm
                  end={params.end}
                  onSubmit={onSubmit}
                  start={params.start}
                />
              </p>
            </details>
          </div>

          <div className="bg-white mb-4 px-4 py-4 mt-4 rounded-md">
            <BookingSteps current={1} />
          </div>

          <div>
            {StateMachine.isLoading(viewState) ? (
              <SkeletonList key="skeleton_list" />
            ) : (
              <>
                {StateMachine.isError(viewState) ? (
                  <EmptyVehicle key="empty_vehicle" />
                ) : (
                  <>
                    {dataVehicle?.map((vehicle: VehicleSearched) => (
                      <CardGroupVehicle
                        className="mb-4"
                        key={vehicle.uCode}
                        onClick={() => onSelectVehicle(vehicle)}
                        vehicle={vehicle}
                      />
                    ))}

                    {dataVehicle?.length <= 0 && (
                      <EmptyVehicle key="empty_vehicle" />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default UserSearchVehicleAvailable
