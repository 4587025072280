import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import { Card } from "antd"
import Button from "components/atom/button"
import CreditCardEmpty from "components/molecule/empty/creditcard"
import Loading from "components/molecule/loading"
import { AppState } from "store"
import * as CreditCardAction from "store/creditcard/Action"

import CreditCardData from "./cardData"
import CreditCardForm from "./cardForm"

const CreditCard: React.FC<any> = ({
  className,
  creditcard,
  data,
  form,
  hideSecurityFields,
  isCompany = false,
  loading,
  selectedCreditCard,
  setDefault,
}) => {
  const [addCard, setAddCard] = useState(false)
  const [defaultParams, setDefaultParams] = useState({})

  useEffect(() => {
    creditcard()
  }, [])

  const onSelect = (card: any) => {
    setDefaultParams({})
    selectedCreditCard(card)
    setDefault(card.cardHash)
  }

  const onCreateCreditCard = (data: any) => {
    setDefaultParams(data)
    setAddCard(false)
    creditcard()
  }

  return (
    <Card className={className}>
      {loading ? (
        <Loading text="Carregando seu cartões..." />
      ) : (
        <>
          {addCard ? (
            <CreditCardForm
              isCompany={isCompany}
              onCancel={() => setAddCard(false)}
              onCreateSuccess={onCreateCreditCard}
            />
          ) : (
            <>
              {data?.length ? (
                <div>
                  <div className="_text-2 _font-weight-bold _mt-2 _mb-4 _text-uppercase">
                    Seus cartões
                  </div>
                  {data?.map((card: any) => (
                    <CreditCardData
                      card={card}
                      defaultParams={defaultParams}
                      form={form}
                      hideSecurityFields={hideSecurityFields}
                      key={card.cardHash}
                      onSelect={() => onSelect(card)}
                    />
                  ))}

                  <Button
                    className="_mt-6"
                    onClick={() => setAddCard(true)}
                    theme="outline-primary"
                  >
                    {" "}
                    + Cadastrar um novo cartão{" "}
                  </Button>
                </div>
              ) : (
                <CreditCardEmpty onAddCreditCard={() => setAddCard(true)} />
              )}
            </>
          )}
        </>
      )}
    </Card>
  )
}

const mapStateToProps = ({ creditcard }: AppState) => {
  return {
    data: creditcard?.data,
    loading: creditcard?.loading,
  }
}

const mapDispatchToProps = {
  creditcard: CreditCardAction.creditcard,
  selectedCreditCard: CreditCardAction.selectedCreditCard,
  setDefault: CreditCardAction.setDefault,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditCard) as any
