import React from "react"

import "@fullcalendar/react/dist/vdom"
import * as Sentry from "@sentry/react"
import { Result } from "antd"
import "antd/dist/antd.css"
import "libs/firebase"
import moment from "moment-timezone"
import "moment/locale/pt-br"
import "react-day-picker/style.css"
import { createRoot } from "react-dom/client"

import App from "./App"
import "./index.css"
import "./libs/i18n"
import * as serviceWorker from "./serviceWorker"
import "./variables.scss"

Sentry.init({
  dsn: "https://8e598d020fda82d41f6da1233aa879a9@o4508189669851136.ingest.us.sentry.io/4508189671686144",
  enabled: process.env.REACT_APP_ENV === "production",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0,
  tracePropagationTargets: [/^https:\/\/api\.closecar\.com\.br/],
  tracesSampleRate: 0.5,
})

const userTimeZone = moment.tz.guess()
moment.tz.setDefault(userTimeZone || "America/Sao_Paulo")

function FallbackComponent() {
  return (
    <Result
      subTitle="Ops, algo não se comportou como esperado. Por favor recarregue a página."
      title="Encontramos um problema."
    />
  )
}

const myFallback = <FallbackComponent />

const rootElement = document.getElementById("root")
const root = createRoot(rootElement as HTMLElement)

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={myFallback}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
