import React, { useState } from "react"
import { DayPicker } from "react-day-picker"

import { DatePicker as AntDatePicker, Drawer } from "antd"
import moment from "moment"
import { ptBR } from "react-day-picker/locale"

import useDeviceDetection from "@/hooks/useDeviceDetection"

import "./style.css"

const DatePicker = (props: any) => {
  const [open, setOpen] = useState(false)

  const selected = props.value
  const device = useDeviceDetection()

  const onOpenDrawerTimeMobile = () => {
    setOpen(true)

    setTimeout(() => {
      const value = selected.format("YYYY-MM-DD")

      const elements = document.querySelectorAll(`td[data-day="${value}"]`)
      elements.forEach((element) => {
        element?.scrollIntoView({ behavior: "smooth", block: "center" })
      })
    })
  }

  const onClose = () => {
    setOpen(false)
  }

  // useEffect(() => {
  //   setSelected(moment(props.currentDate).toDate())
  // }, [props.currentDate])

  const onSelectDate = (value: Date) => {
    // setSelected(value)
    props.onSelectMobile?.(moment(value))
    onClose()
  }

  if (device === "mobile") {
    return (
      <>
        <Drawer
          closable
          onClose={onClose}
          open={open}
          placement="bottom"
          size="large"
          title="Selecione uma data"
        >
          <div className="w-full flex flex-col items-center">
            <DayPicker
              classNames={{ months: "w-full" }}
              hideNavigation
              locale={ptBR}
              mode="single"
              numberOfMonths={6}
              onSelect={onSelectDate}
              required
              selected={selected.toDate()}
            />
          </div>
        </Drawer>
        <AntDatePicker
          {...props}
          className="datepicker-disabled-for-mobile"
          defaultValue={props.defaultValue}
          format="DD/MM/YYYY"
          inputReadOnly
          onClick={onOpenDrawerTimeMobile}
          open={false}
          size="large"
          style={{
            width: "100%",
          }}
        />
      </>
    )
  }

  return (
    <AntDatePicker
      {...props}
      defaultValue={props.defaultValue}
      format="DD/MM/YYYY"
      onOk={props.onSelect}
      onSelect={props.onSelect}
      size="large"
      style={{
        width: "100%",
      }}
    />
  )
}

export default DatePicker
