import React, { useImperativeHandle, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { AutoComplete as AntAutoComplete, Drawer, Input, InputRef } from "antd"
import StateMachine from "libs/statemachine"
import { useGetterStore } from "store/getter/Hooks"

import useDeviceDetection from "@/hooks/useDeviceDetection"

import "./index.css"

export type AutoCompleteRefProps = {
  selectItem: ({ value }: { value: string }) => void
}

const { Search } = Input

const AutoComplete = React.forwardRef<AutoCompleteRefProps, any>(
  (props, ref) => {
    const refInputDrawer = useRef<InputRef>(null)
    const { t } = useTranslation()
    const device = useDeviceDetection()

    const [open, setOpen] = useState(false)
    const [selectedValue, setSelectedValue] = useState("")

    const controller = useRef<AbortController>()

    const startLengthSearch = props.startLengthSearch || 3

    const {
      data = [],
      getData,
      viewState,
    } = useGetterStore({
      feature: props.feature,
    })

    const selectItem = ({ value }: { value: string }) => {
      setSelectedValue(value)
    }

    useImperativeHandle(ref, () => ({
      selectItem,
    }))

    const handleSearch = (value: string) => {
      setSelectedValue(value)

      if (value?.length >= startLengthSearch) {
        if (controller.current) {
          controller.current?.abort()
          controller.current = new AbortController()
        } else {
          controller.current = new AbortController()
        }

        const { signal } = controller.current
        getData({ queryParams: { [props.queryParam]: value }, signal })
      }

      props.onChangeValue?.(value)
    }

    const internalMapSelectedOption = (value: string) => {
      if (props.multiSelect) {
        const item = data.reduce((result: any, item: any) => {
          if (result) return result

          const selected = item.options.find(
            (opt: any) => opt[props.keyData] === value,
          )

          if (selected) {
            return selected
          }
        }, null)
        return item
      } else {
        const item = data.find((item: any) => item[props.keyData] === value)
        props.onSelect?.(item)
        return item
      }
    }

    const internalOnSelect = (value: string) => {
      setSelectedValue(value)

      const item = internalMapSelectedOption(value)
      props.onSelect?.(item)
    }

    const renderItemOptions = (item: any) => {
      switch (item.type) {
        case "airport":
          return {
            label: (
              <div>
                <div>{item[props.keyData]}</div>
                <div className="!text-xs">
                  {item.city} - {item.state}
                </div>
              </div>
            ),
            value: item[props.keyData],
          }

        default:
          return {
            label: item[props.keyData],
            value: item[props.keyData],
          }
      }
    }

    const renderOptions = () => {
      if (props.multiSelect) {
        return data?.map((item: any) => {
          return {
            label: t(item.label),
            options: item.options.map((opt: any) => {
              return renderItemOptions(opt)
            }),
          }
        })
      } else {
        return data?.map((item: any) => {
          return {
            label: item[props.keyData],
            value: item[props.keyData],
          }
        })
      }
    }

    const onOpenDrawerTimeMobile = () => {
      setOpen(true)

      setTimeout(() => {
        refInputDrawer.current?.focus()
      }, 500)
    }

    const onClose = () => {
      setOpen(false)
    }

    const onChangeMobileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      handleSearch(e.target.value)
    }

    if (device === "mobile") {
      return (
        <>
          <Drawer
            closable
            onClose={onClose}
            open={open}
            placement="bottom"
            size="large"
            title={props.mobileTitle}
          >
            <div>
              <Search
                allowClear={props.allowClear || false}
                loading={props.loading || StateMachine.isLoading(viewState)}
                onChange={onChangeMobileInput}
                placeholder={props.placeholder}
                ref={refInputDrawer}
                size="large"
                type="text"
              />

              <div className="mt-4">
                {data?.map((item: any) => {
                  return (
                    <div key={item.label}>
                      <div className="p-2 bg-gray-200 font-bold uppercase">
                        {t(item.label)}
                      </div>
                      <div>
                        {item.options.map((opt: any) => {
                          return (
                            <div
                              className="cursor-pointer hover:bg-gray-200 py-4"
                              key={opt.place}
                              onClick={() => {
                                internalOnSelect(opt.place)
                                onClose()
                              }}
                            >
                              {opt.place}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
                {StateMachine.isLoaded(viewState) && data?.length === 0 && (
                  <div className="text-center">
                    Não encontramos locais com a sua busca.
                  </div>
                )}
              </div>
            </div>
          </Drawer>

          <Search
            className="autocomplete-disabled-for-mobile"
            onClick={onOpenDrawerTimeMobile}
            readOnly
            size="large"
            value={selectedValue}
          />
        </>
      )
    }

    return (
      <AntAutoComplete
        className={props.className}
        defaultValue={props?.defaultValue || ""}
        disabled={props.disabled || false}
        dropdownMatchSelectWidth={252}
        onBlur={props.onBlur || undefined}
        onFocus={props.onFocus}
        onKeyDown={props.onKeyDown}
        onSearch={handleSearch}
        onSelect={internalOnSelect}
        options={renderOptions()}
        style={{
          width: "100%",
        }}
        value={selectedValue}
      >
        <Search
          allowClear={props.allowClear || false}
          autoComplete="off"
          disabled={props.disabled || false}
          loading={props.loading || StateMachine.isLoading(viewState)}
          placeholder={props.placeholder || "digite o texto"}
          size="large"
          status={props.status}
          type="text"
          value={selectedValue}
        />
      </AntAutoComplete>
    )
  },
)

AutoComplete.displayName = "AutoComplete"

export default AutoComplete
