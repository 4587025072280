import Config, { Environment } from "libs/config"

class TrackLog {
  static logError(tag: string, local: string, data: any) {
    // if (Config.environment === Environment.Development) {
    //   console.log("-------- LOG ERROR ----------")
    //   console.log(tag, local, data)
    //   console.log("-------- xxxxxxxxx ----------")
    // }
  }
}

export default TrackLog
