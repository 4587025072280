import React, { PropsWithChildren } from "react"

type InternalTextProps = { className?: string; block?: boolean }

type TextProps = PropsWithChildren<InternalTextProps>

function Text(props: TextProps) {
  return <span {...props}>{props.children}</span>
}

const mergeTextProps = ({ block, className }: InternalTextProps) => {
  let mergedClassName = ""

  if (block) {
    mergedClassName += " block"
  }

  if (className) {
    mergedClassName += ` ${className}`
  }

  return mergedClassName
}

export function Headline1({ block, children, className }: TextProps) {
  return (
    <Text
      className={`text-6xl sm:text-7xl md:text-8xl${mergeTextProps({ block, className })}`}
    >
      {children}
    </Text>
  )
}

export function Headline2({ block, children, className }: TextProps) {
  return (
    <Text
      className={`text-5xl sm:text-6xl md:text-7xl${mergeTextProps({ block, className })}`}
    >
      {children}
    </Text>
  )
}

export function Headline3({ block, children, className }: TextProps) {
  return (
    <Text
      className={`text-4xl sm:text-5xl md:text-6xl${mergeTextProps({ block, className })}`}
    >
      {children}
    </Text>
  )
}

export function Headline4({ block, children, className }: TextProps) {
  return (
    <Text
      className={`text-3xl sm:text-4xl md:text-5xl${mergeTextProps({ block, className })}`}
    >
      {children}
    </Text>
  )
}

export function Headline5({ block, children, className }: TextProps) {
  return (
    <Text
      className={`text-2xl sm:text-3xl md:text-4xl${mergeTextProps({ block, className })}`}
    >
      {children}
    </Text>
  )
}

export function Headline6({ block, children, className }: TextProps) {
  return (
    <Text
      className={`text-xl sm:text-2xl md:text-3xl${mergeTextProps({ block, className })}`}
    >
      {children}
    </Text>
  )
}

export function Title({ block, children, className }: TextProps) {
  return (
    <Text
      className={`text-lg sm:text-xl md:text-2xl${mergeTextProps({ block, className })}`}
    >
      {children}
    </Text>
  )
}

export function Subtitle({ block, children, className }: TextProps) {
  return (
    <Text
      className={`text-base sm:text-lg md:text-xl${mergeTextProps({ block, className })}`}
    >
      {children}
    </Text>
  )
}

export function Subtitle2({ block, children, className }: TextProps) {
  return (
    <Text
      className={`text-base sm:text-lg md:text-xl${mergeTextProps({ block, className })}`}
    >
      {children}
    </Text>
  )
}

export function Body({ block, children, className }: TextProps) {
  return (
    <Text
      className={`text-sm sm:text-base md:text-lg${mergeTextProps({ block, className })}`}
    >
      {children}
    </Text>
  )
}

export function Caption({ block, children, className }: TextProps) {
  return (
    <Text
      className={`text-xs md:text-sm${mergeTextProps({ block, className })}`}
    >
      {children}
    </Text>
  )
}

type TextComponent = typeof Text & {
  Headline1: React.FC<TextProps>
  Headline2: React.FC<TextProps>
  Headline3: React.FC<TextProps>
  Headline4: React.FC<TextProps>
  Headline5: React.FC<TextProps>
  Headline6: React.FC<TextProps>
  Title: React.FC<TextProps>
  Subtitle: React.FC<TextProps>
  Subtitle2: React.FC<TextProps>
  Body: React.FC<TextProps>
  Caption: React.FC<TextProps>
}

const TextWithSubcomponents = Text as TextComponent

TextWithSubcomponents.Headline1 = Headline1
TextWithSubcomponents.Headline2 = Headline2
TextWithSubcomponents.Headline3 = Headline3
TextWithSubcomponents.Headline4 = Headline4
TextWithSubcomponents.Headline5 = Headline5
TextWithSubcomponents.Headline6 = Headline6
TextWithSubcomponents.Title = Title
TextWithSubcomponents.Subtitle = Subtitle
TextWithSubcomponents.Subtitle2 = Subtitle2
TextWithSubcomponents.Body = Body
TextWithSubcomponents.Caption = Caption

export default TextWithSubcomponents
