import React from 'react'
import { Form, InputNumber } from 'antd'
import Text from 'components/atom/text'

interface FormNumberProps {
  label?: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  min?: number;
  max?: number;
  defaultValue?: number;
}

export default function FormInputNumber(props: FormNumberProps) {
  const rules = []

  if (props?.required) {
    rules.push({ required: true, message: 'Este campo não pode ser vazio.' })
  }

  return (
    <div>
      {props.label && (
        <Text className="_text-2">{props.label}{props.required && ' *'}</Text>
      )}

      <Form.Item name={props.name} rules={rules} className={props.className} hasFeedback>
        <InputNumber 
          size="large"
          min={props.min} 
          max={props.max} 
          style={{ width: '100%' }}
          defaultValue={props.defaultValue}
          disabled={props.disabled || false} />
      </Form.Item>
    </div>
  )
}