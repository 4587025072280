class Utils {
  static phoneFormat(string: string): string {
    const ddd = string.substring(0, 2)
    const firstDigit = string.substring(2, 3)
    const phone = string.substring(3)

    // Checking for 0800
    if (ddd === "08") {
      return `${string.substring(0, 4)} ${string.substring(4, 7)} ${string.substring(7, 11)}`
    }

    if (Number(firstDigit) <= 4) {
      return `(${ddd}) ${firstDigit}${phone}`
    }

    return `(${ddd}) ${firstDigit} ${phone}`
  }

  static cleanString(string: string, removeSpace: boolean = true): string {
    if (!string || typeof string !== "string") {
      return ""
    }

    if (removeSpace) {
      string = string.replace(/ /g, "")
    }

    string = string.replace(/-/g, "")
    string = string.replace(/_/g, "")
    string = string.replace(/\//g, "")
    string = string.replace(/,/g, "")
    string = string.replace(/\./g, "")
    string = string.replace(/:/g, "")
    string = string.replace(/\(/g, "")
    string = string.replace(/\)/g, "")
    string = string.replace(/"/g, "")
    string = string.replace(/'/g, "")

    return string
  }

  static objectsAreEquals(obj1: any, obj2: any): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  }
}

export default Utils
