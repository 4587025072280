import Config, { Environment } from "@/libs/config"

const apis: Record<string, string> = {
  [Environment.Development]: "http://localhost:3018",
  [Environment.Homolog]: "https://dev-api.closecar.com.br/vehicle",
  [Environment.Production]: "https://api.closecar.com.br/vehicle",
}

export const vehicleApi = () => {
  const enviroment = Config.environment.vehicle

  return apis[enviroment]
}
