import React, { Suspense } from "react"

import PageCompanyLoading from "components/organism/page_company_loading"
import PageLoading from "components/organism/page_loading"

import { LayoutCompany } from "@/components/organism"

export const withSuspense = (WrappedComponent: any) => {
  return (props: any) =>
    (
      <Suspense fallback={<PageLoading />}>
        <WrappedComponent {...props} />
      </Suspense>
    ) as any
}

export const withSuspensePainel = (WrappedComponent: any) => {
  return (props: any) =>
    (
      <LayoutCompany>
        <Suspense fallback={<PageCompanyLoading />}>
          <WrappedComponent {...props} />
        </Suspense>
      </LayoutCompany>
    ) as any
}
