import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

import FetchApi from "@/libs/fetch"
import { mappedApis } from "@/libs/fetch/apis"
import { StateMachineType } from "@/libs/statemachine"
import Storage from "@/libs/storage"

type State = {
  viewState: StateMachineType
  data: any
}

const initialState: State = {
  data: null,
  viewState: StateMachineType.NotStarted,
}

export const checkToken = createAsyncThunk("auth/check", async () => {
  if (Storage.hasAuthorization()) {
    const fetch = await FetchApi.get({
      hasAuth: true,
      shouldRedirect: false,
      url: mappedApis.auth.check.get.url,
    })

    if (fetch.status === FetchApi.HttpErrorUnauthorized) {
      Storage.clear()
      throw new Error("invalid_token")
    }

    return fetch.data
  }

  throw new Error("user_not_logged")
})

const accountSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(checkToken.pending, (state) => {
        state.viewState = StateMachineType.Loading
      })
      .addCase(checkToken.fulfilled, (state, action: PayloadAction<any>) => {
        state.viewState = StateMachineType.Loaded
        state.data = action.payload
      })
      .addCase(checkToken.rejected, (state) => {
        state.viewState = StateMachineType.Error
      })
  },
  initialState,
  name: "checktoken",
  reducers: {},
})

export default accountSlice.reducer
