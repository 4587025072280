import React from "react"

import { Skeleton } from "antd"

import "./styles.scss"

const PageCompanyLoading = () => {
  return (
    <>
      <Skeleton active className="mb-4" />
      <Skeleton active className="mb-4" />
      <Skeleton active />
    </>
  )
}

export default PageCompanyLoading
