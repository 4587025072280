import React from 'react'
import { Card } from 'antd'
import { Headline4 } from 'components/atom/text'
import { ReactComponent as EmptyStoreSvg } from './assets/empty_store.svg'

import './index.scss'

type Props = {
  className?: string;
}

const EmptyVehicle = ({ className }: Props) => {
  return (
    <Card className={`empty-store-content ${className? className : ''}`}>
      <div className='mt-4'>
        <Headline4>
          Não encontramos nenhum veículo disponível na plataforma.
        </Headline4>
      </div>
      
      <div className="flex justify-center items-center h-screen">
        <EmptyStoreSvg className="empty-store-img" />
      </div>
    </Card>
  )
}

export default EmptyVehicle