import { Dispatch } from "redux"

import * as SigninEffect from "./Effect"

export interface SigninParams {
  email: string
  password: string
}

export interface RequestPassword {
  email: string
}

export interface ChangePassword {
  password: string
  token: string
}

export const signin =
  ({ email, password }: SigninParams) =>
  async (dispatch: Dispatch) => {
    SigninEffect.setLoading(dispatch, true)
    const authorization = await SigninEffect.signin({ email, password })

    if (authorization.hasError) {
      SigninEffect.setError(dispatch, authorization.data)
    } else {
      SigninEffect.setSuccess(dispatch, true)
    }

    SigninEffect.setLoading(dispatch, false)

    return { data: authorization.data, hasError: authorization.hasError }
  }

export const requestPassword =
  ({ email }: RequestPassword) =>
  async (dispatch: Dispatch) => {
    SigninEffect.setLoading(dispatch, true)
    const request = await SigninEffect.requestPassword({ email })

    if (request.hasError) {
      SigninEffect.setError(dispatch, request.data)
    } else {
      SigninEffect.setSuccess(dispatch, true)
    }

    SigninEffect.setLoading(dispatch, false)

    return { data: request.data, hasError: request.hasError }
  }

export const changePassword =
  ({ password, token }: ChangePassword) =>
  async (dispatch: Dispatch) => {
    SigninEffect.setLoading(dispatch, true)
    const request = await SigninEffect.changePassword({ password, token })

    if (request.hasError) {
      SigninEffect.setError(dispatch, request.data)
    } else {
      SigninEffect.setSuccess(dispatch, true)
    }

    SigninEffect.setLoading(dispatch, false)

    return { data: request.data, hasError: request.hasError }
  }

export const clean = () => async (dispatch: Dispatch) => {
  SigninEffect.setLoading(dispatch, false)
  SigninEffect.setError(dispatch, null)
  SigninEffect.setSuccess(dispatch, null)
}
