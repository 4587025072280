import React, { PropsWithChildren, useEffect } from "react"
import { useHistory } from "react-router-dom"

import { Alert, Divider, Skeleton } from "antd"
import MenuCompany from "components/organism/menu_company"

import { Text } from "@/components/atom"
import StateMachine from "@/libs/statemachine"
import { useAccountStore } from "@/store/account/useAccountStore"

import "./styles.scss"

type LayoutCompanyProps = PropsWithChildren

const Loading = () => (
  <div className="mt-4">
    <Skeleton active />
  </div>
)

const Error = () => (
  <div className="mt-4">
    <Alert
      description="Tente recarregar a página."
      message="Ops! Tivemos um problema para carregar os dados."
      type="error"
    />
  </div>
)

const LayoutCompany = ({ children }: LayoutCompanyProps) => {
  const { accountInfo, executeAccountInfo } = useAccountStore()

  const loadAccountInfo = async () => {
    if (StateMachine.isLoaded(accountInfo?.viewState)) {
      if (accountInfo?.data?.uCode) {
        return
      }
    }

    executeAccountInfo()
  }

  useEffect(() => {
    loadAccountInfo()
  }, [])

  if (StateMachine.isLoadingOrNotStarted(accountInfo.viewState)) {
    return (
      <div className="organism-company-content">
        <div className="px-6 pt-8 organism-company-content__menu">
          <Loading />
        </div>
        <div className="px-6 pt-8 organism-company-content__body">
          <Loading />
        </div>
      </div>
    )
  }

  const headerChild = React.Children.toArray(children).find(
    (child) =>
      React.isValidElement(child) &&
      child.type === LayoutCompanyWithSubcomponents.Header,
  )

  const contentChild =
    React.Children.toArray(children).find(
      (child) =>
        React.isValidElement(child) &&
        child.type === LayoutCompanyWithSubcomponents.Content,
    ) || children

  return (
    <div className="organism-company-content">
      <div className="organism-company-content__menu">
        <MenuCompany />
      </div>
      <div className="pl-6 pr-6 pt-8 organism-company-content__body">
        {headerChild}
        {contentChild}
      </div>
    </div>
  )
}

type HeaderProps = PropsWithChildren<{
  isLoading?: boolean
}>

const Header = ({ children, isLoading = false }: HeaderProps) => {
  const headerTitleChild = React.Children.toArray(children).find(
    (child) =>
      React.isValidElement(child) &&
      child.type === LayoutCompanyWithSubcomponents.Header.Title,
  )
  const headerSubtitleChild = React.Children.toArray(children).find(
    (child) =>
      React.isValidElement(child) &&
      child.type === LayoutCompanyWithSubcomponents.Header.Subtitle,
  )

  return (
    <div>
      {isLoading ? (
        <Skeleton.Button active block />
      ) : (
        <>
          {headerTitleChild}
          {headerSubtitleChild}
        </>
      )}

      {headerTitleChild || headerSubtitleChild ? <Divider /> : null}
    </div>
  )
}

type HeaderTitleProps = PropsWithChildren<{
  withBack?: boolean
  backRoute?: string | null
}>

const HeaderTitle = ({
  backRoute = null,
  children,
  withBack = false,
}: HeaderTitleProps) => {
  const history = useHistory()

  const onBack = () => {
    if (backRoute) {
      history.push(backRoute)
    } else {
      history.goBack()
    }
  }

  return (
    <Text.Headline5 block>
      {withBack && (
        <>
          <span
            aria-label="voltar"
            className="_cursor_pointer"
            onClick={onBack}
          >
            &larr;
          </span>
          &nbsp;
        </>
      )}
      {children}
    </Text.Headline5>
  )
}

const HeaderSubtitle = ({ children }: PropsWithChildren) => {
  return (
    <Text.Subtitle block className="mt-2">
      {children}
    </Text.Subtitle>
  )
}

type HeaderComponent = typeof Header & {
  Title: React.FC<HeaderTitleProps>
  Subtitle: React.FC<PropsWithChildren>
}

const HeaderWithSubcomponents = Header as HeaderComponent
HeaderWithSubcomponents.Title = HeaderTitle
HeaderWithSubcomponents.Subtitle = HeaderSubtitle

type ContentProps = PropsWithChildren<{
  isLoading?: boolean
  hasError?: boolean
}>

const Content = ({
  children,
  hasError = false,
  isLoading = true,
}: ContentProps) => {
  return <div>{isLoading ? <Loading /> : hasError ? <Error /> : children}</div>
}

type LayoutCompanyComponent = typeof LayoutCompany & {
  Header: HeaderComponent
  Content: React.FC<ContentProps>
}

const LayoutCompanyWithSubcomponents = LayoutCompany as LayoutCompanyComponent

LayoutCompanyWithSubcomponents.Header = HeaderWithSubcomponents
LayoutCompanyWithSubcomponents.Content = Content

export default LayoutCompanyWithSubcomponents
