import React from 'react'
import { useHistory } from 'react-router-dom'
import Menu from 'components/molecule/menu'
import Storage from 'libs/storage'

import "./styles.scss"

const MenuAdmin: React.FC<any> = (props) => {
  const history = useHistory()

  const onLogout = () => {
    Storage.clear()
    history.push(`/`)
  }

  return (
    <div className="content_menu_company">
      <div className="_text-center _mt-8 _mb-8">
        <img src="https://53cars.s3-sa-east-1.amazonaws.com/logo_principal_white.png" className="molecule-menu-admin-logo" />
      </div>
      <Menu name="Dashboard" url="/account/admin/dashboard" />
      <Menu name="Empresas" url="/account/admin/company" />
      <Menu name="Sair" onClick={onLogout} withIcon={false} />
    </div>
  )
}

export default MenuAdmin