import React, { PropsWithChildren } from "react"

import { Skeleton } from "antd"

import "./styles.scss"

type LayoutCompanyProps = PropsWithChildren<{
  isLoading?: boolean
  hasHeader?: boolean
}>

const Loading = () => (
  <div className="mt-8 mx-10">
    <Skeleton active />
    <Skeleton active className="mt-6" />
  </div>
)

const Layout = ({
  children,
  hasHeader = true,
  isLoading,
}: LayoutCompanyProps) => {
  const headerChild = React.Children.toArray(children).find(
    (child) =>
      React.isValidElement(child) &&
      child.type === LayoutWithSubcomponents.Header,
  )

  const contentChild =
    React.Children.toArray(children).find(
      (child) =>
        React.isValidElement(child) &&
        child.type === LayoutWithSubcomponents.Content,
    ) || children

  return (
    <article
      className={
        hasHeader ? `atom-layout-base` : `atom-layout-base-without-header`
      }
    >
      {headerChild}
      {isLoading ? <Loading /> : contentChild}
    </article>
  )
}

const Header = ({ children }: PropsWithChildren) => {
  return <div>{children}</div>
}

const Content = ({ children }: PropsWithChildren) => {
  return <div>{children}</div>
}

type LayoutCompanyComponent = typeof Layout & {
  Header: React.FC<PropsWithChildren>
  Content: React.FC<PropsWithChildren>
}

const LayoutWithSubcomponents = Layout as LayoutCompanyComponent

LayoutWithSubcomponents.Header = Header
LayoutWithSubcomponents.Content = Content

export default LayoutWithSubcomponents
