import React from "react"

import "./styles.scss"

export enum GridDirection {
  Rows = "rows",
  Columns = "columns",
}

export enum GridSize {
  One = "1",
  Two = "2",
  Three = "3",
  Four = "4",
  Five = "5",
}

interface GridProps {
  children: React.ReactNode
  className?: string
  direction?: GridDirection
  size: GridSize | number | string
}

const Grid: React.FC<GridProps> = (props) => {
  return (
    <div
      className={`atom-grid-${props.direction || "columns"}-content-${props.size} ${props.className ? props.className : ""}`}
    >
      {props.children}
    </div>
  )
}

type GridComponent = typeof Grid & {
  Size: typeof GridSize
  Direction: typeof GridDirection
}

const GridWithSubcomponents = Grid as GridComponent

GridWithSubcomponents.Size = GridSize
GridWithSubcomponents.Direction = GridDirection

export default GridWithSubcomponents
