import React from 'react'
import Text from 'components/atom/text'
import Button from 'components/atom/button'

import { ReactComponent as CreditCardSvg } from './assets/empty_creditcard.svg'

import './index.scss'

const CreditCardEmpty: React.FC<any> = ({ onAddCreditCard }) => {
  return (
    <div className="empty-creditcard-content">
      <div>
        <Text className="_text-3">Você ainda não tem nenhum cartão cadastrado.</Text>
      </div>
      <div className='flex justify-center mt-6'>
        <CreditCardSvg className="empty-creditcard-img" />
      </div>
      <Button onClick={onAddCreditCard}> + Cadastrar um novo cartão </Button>
    </div>
  )
}

export default CreditCardEmpty