import { StateMachineType } from "libs/statemachine"
import { createSelector } from "reselect"
import { AppState } from "store"
import { IExecuterState } from "store/executer/Reducer"

export interface ExecuterSelector {
  data: { [key: string]: any | null }
  viewState: { [key: string]: StateMachineType }
}

function executerData({ data, viewState }: IExecuterState): ExecuterSelector {
  return { data, viewState }
}

export const executerSelector = createSelector<AppState, any>(
  (state) => state?.executer,
  executerData,
)
