import FetchApi from "libs/fetch"
import { StateMachineType } from "libs/statemachine"
import { Dispatch } from "redux"
import ActionType from "store/executer/ActionType"
import * as ActionUtility from "store/utilities/actionUtility"

export enum HttpVerb {
  Post = "POST",
  Put = "PUT",
  Delete = "DELETE",
}

const FetchApiInstance = {
  [HttpVerb.Delete]: FetchApi.delete,
  [HttpVerb.Post]: FetchApi.post,
  [HttpVerb.Put]: FetchApi.put,
}

export const executeAction = async ({
  bodyParams,
  httpVerb,
  signal,
  url,
}: {
  url: string
  httpVerb: HttpVerb
  bodyParams: any
  signal?: AbortSignal
}) => {
  const fetch = await FetchApiInstance[httpVerb]({
    hasAuth: true,
    options: {
      signal,
    },
    params: bodyParams,
    url,
  })

  return {
    data: fetch.data,
    hasError: FetchApi.hasError(fetch.status),
  }
}

export const setData = (dispatch: Dispatch, payload: PayloadDataExecuter) =>
  dispatch(ActionUtility.createAction(ActionType.EXECUTER_DATA, payload))

export const setViewState = (
  dispatch: Dispatch,
  payload: PayloadViewStateExecuter,
) =>
  dispatch(ActionUtility.createAction(ActionType.EXECUTER_VIEW_STATE, payload))

interface PayloadDataExecuter {
  data: any | null
  feature: string
}

interface PayloadViewStateExecuter {
  feature: string
  viewState: StateMachineType
}
