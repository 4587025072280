import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import ImageLoad from "components/atom/image_load"
import EmptyBooking from "components/molecule/empty/booking"
import Loading, { Rendering } from "components/molecule/loading"
import { StateMachineType } from "libs/statemachine"
import DescriptionVehicle from "pages/UserSearchVehicleAvailable/components/descriptionVehicle"
import { FeatureGetter } from "store/getter/Action"
import { useGetterStore } from "store/getter/Hooks"

import Card from "@/components/molecule/card"
import Utils from "@/libs/utils"

import "./styles.scss"

const AccountUserBooking: React.FC<any> = () => {
  const { t } = useTranslation()

  const {
    data: dataBooking = [],
    getData: getBookings,
    viewState,
  } = useGetterStore({
    feature: FeatureGetter.GetBooking,
  })

  useEffect(() => {
    getBookings()
  }, [])

  const renderStateNotStarted = () => <Rendering className="_mb-6" />

  const renderStateLoading = () => <Loading className="_mb-6" />

  const renderStateLoaded = () => (
    <div className="mx-6 md:mx-0 mb-6">
      {dataBooking?.map((book: any) => {
        const numberOfDay = book?.charges?.find(
          (charge: any) => charge.type === "charge_total_number_of_days",
        )
        const chargeTotalDay = book?.charges?.find(
          (charge: any) => charge.type === "charge_total_day",
        )
        const chargeProtectionTotal = book?.charges?.find(
          (charge: any) => charge.type === "charge_protection_total",
        )
        const chargeAdditionalTotal = book?.charges?.find(
          (charge: any) => charge.type === "charge_additional_total",
        )
        const chargeTotalFee = book?.charges?.find(
          (charge: any) => charge.type === "charge_total_fee",
        )
        const chargeTotalUser = book?.charges?.find(
          (charge: any) => charge.type === "charge_total_user_rent",
        )
        return (
          <Card className="mb-4" key={book.uCode}>
            <div>
              <div className="flex flex-col md:flex-row items-center mb-6">
                <ImageLoad url={book.vehicle?.pictureURL} width={180} />
                <div className="ml-4">
                  <div className="text-2xl mb-2 text-center md:text-left">
                    Código&nbsp;
                    <span className="font-bold">{book.reservationCode}</span>
                  </div>
                  <div className="text-lg text-center md:text-left">
                    {book.vehicle.vehicleGroupType.name}
                  </div>
                  <div className="mb-3 text-base text-center md:text-left">
                    {book.vehicle.vehicleGroupType.description}
                  </div>
                  <DescriptionVehicle vehicle={book.vehicle} />
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12 md:col-span-3">
                  <div className="text-2xl mb-2 font-bold">{t("pick_up")}</div>
                  <div className="text-lg mb-2">{book.startRentDate}</div>
                  <div className="text-base">{book.pickup}</div>
                </div>

                <div className="col-span-12 md:col-span-3">
                  <div className="text-2xl mb-2 font-bold">{t("drop_off")}</div>
                  <div className="text-lg mb-2">{book.endRentDate}</div>
                  <div className="text-base">{book.dropoff}</div>
                </div>

                <div className="col-span-12 md:col-span-3">
                  <div className="text-2xl mb-2 font-bold">{t("place")}</div>
                  <div className="text-lg mb-2">{book.company.tradingName}</div>
                  <div className="text-base">{book.company.email}</div>
                  <div className="text-base">
                    {Utils.phoneFormat(book.company.phone)}
                  </div>
                </div>

                {!book.hiddenPrices && (
                  <div className="col-span-12 md:col-span-3">
                    <div className="text-2xl mb-2 font-bold">
                      {t("charges")}
                    </div>
                    <div className="text-lg mb-4">
                      Total de dias de locação: {numberOfDay?.amount} dia(s)
                    </div>
                    <div className="text-base mb-2">
                      Total das diárias: {chargeTotalDay?.amountFormat}
                    </div>
                    <div className="text-base mb-2">
                      Total dos adicionais:&nbsp;
                      {chargeAdditionalTotal?.amountFormat}
                    </div>
                    <div className="text-base mb-2">
                      Total das proteções: {chargeProtectionTotal?.amountFormat}
                    </div>
                    <div className="text-base mb-4">
                      Taxas: {chargeTotalFee?.amountFormat}
                    </div>
                    <div className="text-2xl">
                      Total: {chargeTotalUser?.amountFormat}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Card>
        )
      })}

      {dataBooking && dataBooking?.length <= 0 && <EmptyBooking />}
    </div>
  )

  const rendering = () => {
    switch (viewState) {
      case StateMachineType.NotStarted:
        return renderStateNotStarted()
      case StateMachineType.Loading:
        return renderStateLoading()
      case StateMachineType.Loaded:
        return renderStateLoaded()
    }
  }

  return <>{rendering()}</>
}

export default AccountUserBooking
