import React from "react"

import { Form } from "antd"
import Text from "components/atom/text"

import Select, { SelectOption } from "@/components/atom/select"

interface FormSelectProps {
  allowClear?: boolean
  bordered?: boolean
  className?: string
  disabled?: boolean
  extras?: string
  hasFeedback?: boolean
  label?: string
  loading?: boolean
  mobileTitle?: string
  name: string
  onClear?: () => void
  onSelect?: (value: any, option: any) => void
  option?: SelectOption[]
  placeholder?: string
  required?: boolean
  selectClassName?: string
  showSearch?: boolean
}

const FormSelect: React.FC<FormSelectProps> = (props) => {
  const form = Form.useFormInstance()
  const rules = []

  if (props?.required) {
    rules.push({ message: "Este campo não pode ser vazio.", required: true })
  }

  const onSelectMobile = (value: any) => {
    form.setFieldValue(props.name, value)
  }

  return (
    <div>
      {props.label && (
        <Text className="_text-2">
          {props.label}
          {props.label && props.required && " *"}
        </Text>
      )}

      <Form.Item
        className={props.className}
        extra={props.extras}
        hasFeedback={props.hasFeedback || false}
        name={props.name}
        rules={rules}
      >
        <Select
          {...props}
          allowClear={props.allowClear || false}
          bordered={props.bordered || true}
          disabled={props.disabled || false}
          loading={props.loading || false}
          mobileTitle={props.mobileTitle || props.label}
          onClear={props?.onClear}
          onSelect={props.onSelect}
          onSelectMobile={onSelectMobile}
          option={props.option || []}
          placeholder={props.placeholder}
          selectClassName={props.selectClassName}
          showSearch={props.showSearch || false}
        />
      </Form.Item>
    </div>
  )
}

export default FormSelect
