import { ErrorPayload } from "libs/fetch/interface"

import baseReducer from "../utilities/baseReducer"
import ActionType from "./ActionType"

export interface ISigninState {
  readonly error: ErrorPayload | null
  readonly loading: boolean | null
  readonly success: boolean | null
  readonly user: any | null
}

const initialState: ISigninState = {
  error: null,
  loading: false,
  success: null,
  user: null,
}

const landingReducer = baseReducer(initialState, {
  [ActionType.SIGNIN_ERROR](state, action) {
    return {
      ...state,
      error: action.payload,
    }
  },
  [ActionType.SIGNIN_LOADING](state, action) {
    return {
      ...state,
      loading: action.payload,
    }
  },
  [ActionType.SIGNIN_SUCCESS](state, action) {
    return {
      ...state,
      success: action.payload,
    }
  },
  [ActionType.SIGNIN_USER](state, action) {
    return {
      ...state,
      user: action.payload,
    }
  },
})

export default landingReducer
