import React from "react"

import type { CheckboxValueType } from "antd/lib/checkbox/Group"

import { Checkbox, Form, Space } from "antd"
import Text from "components/atom/text"

interface ProtectionAndAdditionalProps {
  disabled?: boolean
  name: string
  onChange?: (checkedValue: CheckboxValueType[]) => void
  options: Array<{ value: any; label: string; data: any }>
  required?: boolean
  type: "protection" | "equipment"
}

const ProtectionAndAdditional: React.FC<ProtectionAndAdditionalProps> = (
  props,
) => {
  const rules = []

  if (props?.required) {
    rules.push({ message: "Este campo não pode ser vazio.", required: true })
  }

  return (
    <div>
      <Form.Item name={props.name} rules={rules}>
        <Checkbox.Group className="w-full" onChange={props?.onChange}>
          <Space className="w-full" direction="vertical">
            {props?.options?.map((option: any) => (
              <div
                className="bg-white border border-slate-200 rounded-md pt-6"
                key={option.value}
              >
                <div className="px-4 pb-6">
                  <div className="flex items-center">
                    <Checkbox
                      disabled={props.disabled || false}
                      value={option.value}
                    />
                    <Text className="text-lg pl-2">{option.label}</Text>
                  </div>
                  <div>
                    <Text className="text-sm">
                      {option.data.info.description}
                    </Text>
                  </div>
                  <div>
                    <Text className="font-bold text-base">
                      {option.data.charge.unitAmountFormat} /{" "}
                      {option.data.charge.billingType === "U"
                        ? "valor único"
                        : "dia"}
                    </Text>
                  </div>
                </div>
                {/* {props.type === "protection" &&
                option.data.coverage.insuranceInfos ? (
                  <div className="mt-2 px-4 py-1 bg-slate-200">
                    <Text className="text-sm">
                      Está proteção assegura um valor de{" "}
                      {option.data.coverage.insuranceInfos.amountFormat}
                    </Text>
                  </div>
                ) : (
                  <div className="pb-4" />
                )} */}
              </div>
            ))}
          </Space>
        </Checkbox.Group>
      </Form.Item>
    </div>
  )
}

export default ProtectionAndAdditional
