import React from 'react'
import MenuAdmin from 'components/organism/menu_admin'

import './styles.scss'

const LayoutAdmin: React.FC<any> = (props) => {

  return (
    <div className="organism-admin-content">
      <div className="organism-admin-content__menu">
        <MenuAdmin />
      </div>
      <div className="_pl-6 _pr-6 _pt-8 organism-admin-content__body">
        {props.children}
      </div>
    </div>
  )
}

export default LayoutAdmin