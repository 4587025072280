import React from "react"

import { LoadingOutlined } from "@ant-design/icons"

import "./styles.scss"

const Button: React.FC<any> = ({
  block,
  children,
  className,
  disabled,
  icon,
  loading,
  onClick,
  theme,
  type = "submit",
}) => {
  const getClass = () => {
    let generateClassName = `atom-button ${className}`

    switch (theme) {
      case "primary":
        generateClassName = `atom-button-primary ${generateClassName}`
        break

      case "secondary":
        generateClassName = `atom-button-secondary ${generateClassName}`
        break

      case "gray":
        generateClassName = `atom-button-gray ${generateClassName}`
        break

      case "outline-black":
        generateClassName = `atom-button-outline-black ${generateClassName}`
        break

      case "outline-primary":
        generateClassName = `atom-button-outline-primary ${generateClassName}`
        break

      case "excel":
        generateClassName = `atom-button-excel ${generateClassName}`
        break

      case "danger":
        generateClassName = `atom-button-danger ${generateClassName}`
        break
    }

    if (block) {
      generateClassName = `atom-button-block ${generateClassName}`
    }

    if (disabled) {
      generateClassName = `${generateClassName} atom-button-disabled`
    }

    if (loading) {
      generateClassName = `${generateClassName} atom-button-loading`
    }

    return generateClassName
  }

  return (
    <>
      {loading ? (
        <button className={getClass()} disabled={true}>
          <LoadingOutlined className="mr-2" spin />
          {children}
        </button>
      ) : (
        <button
          className={getClass()}
          disabled={disabled}
          onClick={onClick}
          type={type}
        >
          {icon}
          {children}
        </button>
      )}
    </>
  )
}

export default Button
