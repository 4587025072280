import React from "react"
import { useHistory, useParams } from "react-router-dom"

import { RightOutlined } from "@ant-design/icons"

import "./styles.scss"

interface MenuProps {
  name: string
  onClick?: any
  url?: string
  withIcon?: boolean
}

const Menu: React.FC<MenuProps> = ({ name, onClick, url, withIcon = true }) => {
  const history = useHistory()
  const { uCode } = useParams<any>()

  const onNavigate = () => {
    if (onClick) {
      onClick?.()
    } else {
      history.push(url || "/")
    }
  }

  const isSelected = () => {
    if (url) {
      if (uCode) {
        return history.location.pathname.indexOf(url) !== -1
      } else {
        return history.location.pathname === url
      }
    }

    return false
  }

  return (
    <div
      className={`molecule-menu-content _p-4 ${isSelected() ? "molecule-menu-content__selected" : ""}`}
      onClick={onNavigate}
    >
      <div className={isSelected() ? "font-bold" : ""}>{name}</div>
      {withIcon && (
        <div className="molecule-menu-content_arrow">
          <RightOutlined
            className={`molecule-menu-content_arrow_icon ${isSelected() ? "molecule-menu-content_arrow_icon__selected" : ""}`}
          />
        </div>
      )}
    </div>
  )
}

export default Menu
