import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { Form } from "antd"
import Text from "components/atom/text"
import Header from "components/organism/header"
import { logEvent } from "firebase/analytics"
import { analytics } from "libs/firebase"
import ManageUrl from "libs/url"
import moment from "moment"

import useDeviceDetection from "@/hooks/useDeviceDetection"
import ManageDate from "@/libs/date"
import { FeatureGetter } from "@/store/getter/Action"

import AutoComplete, { AutoCompleteRefProps } from "../autocomplete"
import FormDatePicker from "../form_datepicker"
import FormTime from "../form_time"
import { QuerySearch } from "./form"
import "./styles.css"

export interface PlaceSelected {
  city: string
  place: string
  state: string
  type: string
  uCode: string
}

const Search = () => {
  const device = useDeviceDetection()

  const { t } = useTranslation()
  const history = useHistory()
  const [form] = Form.useForm()

  const refDropoff = useRef<AutoCompleteRefProps>(null)
  const refPickup = useRef<AutoCompleteRefProps>(null)
  const refSearchBox = useRef<HTMLDivElement | null>(null)

  const [query, setQuery] = useState<QuerySearch>({})

  const [validate, showValidate] = useState<{
    pickup: boolean
    dropoff: boolean
  }>({ dropoff: false, pickup: false })

  const getInitialDates = () => {
    return {
      dropoffDate: ManageDate.nowAddDays(5),
      dropoffHour: "10:00",
      pickupDate: ManageDate.nowAddDays(2),
      pickupHour: "10:00",
    }
  }

  const onFocusWhenMobile = () => {
    if (device === "mobile") {
      refSearchBox.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    }
  }

  const disabledPickupDate = (current: any) => {
    return current && current < moment().endOf("day")
  }

  const onSelectPickupDate = (selected: any) => {
    const format = selected.format("YYYY-MM-DDTHH:mm")
    const newDate = ManageDate.addDays(format, 3)
    form.setFieldValue("dropoffDate", ManageDate.mountDate(newDate))
  }

  const disabledDropoffDate = (current: any) => {
    const pickupDate = form.getFieldValue("pickupDate")
    return current && current < pickupDate
  }

  const onBlur = (type: string) => {
    //@ts-expect-error - not typed;
    if (!query[type]?.uCode) {
      setQuery((prevState: any) => ({ ...prevState, [type]: null }))
      showValidate((prevState: any) => ({ ...prevState, [type]: true }))
    }
  }

  const onChangeValue = (type: string) => {
    setQuery((prevState: any) => ({ ...prevState, [type]: null }))
  }

  const onSelectPickup = (value: PlaceSelected) => {
    setQuery((prevState: any) => ({ ...prevState, pickup: value }))
    showValidate((prevState: any) => ({ ...prevState, pickup: false }))

    refDropoff.current?.selectItem({ value: value.place })
    onSelectDropoff(value)
  }

  const onSelectDropoff = (value: PlaceSelected) => {
    setQuery((prevState: any) => ({ ...prevState, dropoff: value }))
    showValidate((prevState: any) => ({ ...prevState, dropoff: false }))
  }

  const onInternalSubmit = (data: any) => {
    showValidate({ dropoff: false, pickup: false })

    if (!query.pickup?.uCode || !query.dropoff?.uCode) {
      showValidate({
        dropoff: !query.dropoff?.uCode,
        pickup: !query.pickup?.uCode,
      })
      return false
    }

    logEvent(analytics, `searched_by_user`, {
      city: query.pickup.city,
    })

    const result = ManageUrl.mountQueryParams({
      dropoff: query.dropoff.uCode,
      end: `${data.dropoffDate?.format("YYYY-MM-DD")}T${data.dropoffHour}`,
      pickup: query.pickup.uCode,
      start: `${data.pickupDate?.format("YYYY-MM-DD")}T${data.pickupHour}`,
    })

    history.push(`/pages/vehicle/available?${result}`)
  }

  return (
    <div className="flex flex-col bg-[url('https://53cars.s3-sa-east-1.amazonaws.com/background_home_1.jpg')] bg-cover bg-center bg-no-repeat">
      <div>
        <Header withLogo={true} />
      </div>

      <div className="mt-6 mb-6 md:mt-16 md:mb-16 flex justify-center">
        <div className="px-4 py-2 text-center">
          <span className="text-4xl md:text-5xl text-white font-bold drop-shadow-md block">
            Um novo jeito de alugar carro.
          </span>
          <span className="text-xl md:text-2xl mt-4 text-white font-bold drop-shadow-md block">
            Começe seu caminho com a gente.
          </span>
        </div>
      </div>

      <div className="mb-12 md:mb-24 flex flex-1 justify-center">
        <div
          className="w-full max-w-[1024px] mx-4 mb:mx-6 px-6 pt-5 pb-2 bg-white drop-shadow-md rounded-md"
          ref={refSearchBox}
        >
          <Form
            form={form}
            initialValues={getInitialDates()}
            name="basic"
            onFinish={onInternalSubmit}
          >
            <div className="grid grid-cols-12">
              <div className="mb-4 md:mb-0 col-span-12 md:col-span-6 flex flex-col">
                <Text className="block text-base">{t("pickup_place")}</Text>

                <AutoComplete
                  allowClear={true}
                  className="mt-2"
                  // onKeyDown={onSearchingPressEnter}
                  feature={FeatureGetter.GetPlaces}
                  keyData="place"
                  mobileTitle={t("pickup_place")}
                  multiSelect={true}
                  onBlur={() => onBlur("pickup")}
                  onChangeValue={() => onChangeValue("pickup")}
                  onFocus={() => onFocusWhenMobile()}
                  onSelect={(value: PlaceSelected) => onSelectPickup(value)}
                  placeholder={"digite o local de retirada"}
                  queryParam="place"
                  ref={refPickup}
                  status={validate.pickup ? "error" : undefined}
                />
                {validate.pickup && (
                  <div className="_mt-2">
                    Você deve selecionar um local de retirada.
                  </div>
                )}
              </div>
              <div className="md:ml-4 col-span-12 md:col-span-4 grid grid-cols-12">
                <div className="col-span-7 mr-2">
                  <Text className="block text-base">Data</Text>
                  <div className="mt-2">
                    <FormDatePicker
                      disabledDate={disabledPickupDate}
                      inputReadOnly
                      name="pickupDate"
                      onSelect={onSelectPickupDate}
                      required
                    />
                  </div>
                </div>
                <div className="col-span-5">
                  <Text className="block text-base">Hora</Text>
                  <div className="mt-2">
                    <FormTime
                      allowClear={true}
                      name="pickupHour"
                      required
                      showSearch={false}
                    />
                  </div>
                </div>
              </div>
              <div className="hidden md:block md:col-span-2">&nbsp;</div>
            </div>

            <div className="grid grid-cols-12">
              <div className="mb-4 md:mb-0 col-span-12 md:col-span-6">
                <div>
                  <Text className="block text-base">{t("dropoff_place")}</Text>

                  <AutoComplete
                    allowClear={true}
                    className="_mt-2"
                    // onKeyDown={onSearchingPressEnter}
                    feature={FeatureGetter.GetPlaces}
                    keyData="place"
                    multiSelect={true}
                    onChangeValue={() => onChangeValue("dropoff")}
                    onSelect={(value: PlaceSelected) => onSelectDropoff(value)}
                    placeholder={"digite o local de devolução"}
                    queryParam="place"
                    ref={refDropoff}
                    status={validate.dropoff ? "error" : undefined}
                  />
                  {validate.dropoff && (
                    <div className="_mt-2">
                      Você deve selecionar um o local de devolução.
                    </div>
                  )}
                </div>
              </div>
              <div className="md:ml-4 col-span-12 md:col-span-4 grid grid-cols-12">
                <div className="col-span-7 mr-2">
                  <Text className="block text-base">Data</Text>
                  <div className="mt-2">
                    <FormDatePicker
                      disabledDate={disabledDropoffDate}
                      inputReadOnly
                      name="dropoffDate"
                      required
                    />
                  </div>
                </div>
                <div className="col-span-5">
                  <Text className="block text-base">Hora</Text>
                  <div className="mt-2">
                    <FormTime
                      allowClear={true}
                      name="dropoffHour"
                      required
                      showSearch={false}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center mb-4 md:mb-0 md:ml-4 col-span-12 md:col-span-2">
                <div
                  className="py-3 w-full cursor-pointer bg-closecar-primary rounded-full text-center"
                  onClick={() => form.submit()}
                >
                  <span className="text-lg font-bold text-white">Buscar</span>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default Search
