import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

import { StateMachineType } from "libs/statemachine"
import {
  FeatureExecuter,
  executeAction,
  executeReset,
} from "store/executer/Action"
import { executerSelector } from "store/executer/Selector"

interface UseExecuterStore {
  clean: () => void
  data: any
  executeAction: ({
    bodyParams,
    pathParams,
    signal,
  }: {
    bodyParams?: any
    pathParams?: any
    signal?: AbortSignal
  }) => Promise<any>
  viewState: StateMachineType
}

export const useExecuterStore = ({
  feature,
}: {
  feature: FeatureExecuter
}): UseExecuterStore => {
  const dispatch = useDispatch()

  const { data, viewState } = useSelector(executerSelector)

  const _executeAction = useCallback(
    (params = {}) => executeAction({ feature, ...params })(dispatch),
    [dispatch, feature],
  )

  const _executeReset = useCallback(
    () => executeReset({ feature })(dispatch),
    [dispatch, feature],
  )

  return {
    clean: _executeReset,
    data: data[feature] || null,
    executeAction: _executeAction,
    viewState: viewState[feature] || StateMachineType.NotStarted,
  }
}
