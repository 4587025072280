import { getAnalytics } from "firebase/analytics"
import { initializeApp } from "firebase/app"

const firebaseConfig = {
  apiKey: "AIzaSyAWko5e4z6YWWrkKWdIOvyHA0PXUIf96jA",
  appId: "1:250302625045:web:94b7b9040ca394026fe51c",
  authDomain: "closecar-firebase.firebaseapp.com",
  measurementId: "G-5FRVBYX592",
  messagingSenderId: "250302625045",
  projectId: "closecar-firebase",
  storageBucket: "closecar-firebase.appspot.com",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Analytics and get a reference to the service
export const analytics = getAnalytics(app)
