import { Dispatch } from "redux"
import { AppState } from "store"

import * as DocumentEffect from "./Effect"

interface UploadParams {
  documentType?: string
  file: any
  position?: number
  type: string
}

export const upload =
  ({ documentType, file, position, type }: UploadParams) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    DocumentEffect.setLoading(dispatch, true)

    const documentUpload = await DocumentEffect.upload({ file, type })

    if (documentUpload.hasError) {
      DocumentEffect.setError(dispatch, true)
    } else {
      DocumentEffect.setSuccess(dispatch, true)
      const document = getState()?.document

      if (document?.data === null) {
        DocumentEffect.setData(dispatch, [
          {
            documentType,
            fileKey: documentUpload.fileKey,
            position,
            url: documentUpload.url,
          },
        ])
      } else {
        const documents = getState()?.document?.data.filter(
          (doc: any) => doc.fileKey !== documentUpload.fileKey,
        )
        DocumentEffect.setData(dispatch, [
          ...documents,
          {
            documentType,
            fileKey: documentUpload.fileKey,
            position,
            url: documentUpload.url,
          },
        ])
      }
    }

    DocumentEffect.setLoading(dispatch, false)

    return {
      fileKey: documentUpload.fileKey,
      hasError: documentUpload.hasError,
      url: documentUpload.url,
    }
  }

export const uploadBase64 =
  ({ file }: { file: string }) =>
  async () => {
    const documentUpload = await DocumentEffect.uploadBase64({ file })

    return {
      fileKey: documentUpload.fileKey,
      hasError: documentUpload.hasError,
      url: documentUpload.url,
    }
  }

export const remove =
  ({ fileKey }: { fileKey: string }) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const documents = getState()?.document?.data.filter(
      (doc: any) => doc.fileKey !== fileKey,
    )
    DocumentEffect.setData(dispatch, documents)
  }

export const clean = () => async (dispatch: Dispatch) => {
  DocumentEffect.setData(dispatch, null)
}
