import React, { PropsWithChildren } from "react"

import "./styles.scss"

const Body: React.FC<
  PropsWithChildren<{ hasHeader?: boolean; className?: string }>
> = ({ children, className, hasHeader = true }) => {
  return (
    <main
      className={
        hasHeader
          ? `atom-layout-base-body ${className}`
          : `atom-layout-base-body-without-header ${className}`
      }
    >
      {children}
    </main>
  )
}

export default Body
