import React from "react"

import { Form } from "antd"
import Text from "components/atom/text"

import Time from "../time"

type FormTimeProps = {
  name: string
  allowClear?: boolean
  required?: boolean
  hidden?: boolean
  label?: string
  bordered?: boolean
  defaultValue?: string
  loading?: boolean
  showSearch?: boolean
  disabled?: boolean
  onSelect?: (value: string) => void
}

const FormTime: React.FC<FormTimeProps> = (props) => {
  const form = Form.useFormInstance()
  const rules = []

  if (props?.required) {
    rules.push({ message: "Hora inválida.", required: true })
  }

  const onInternalSelect = (value: string) => {
    form.setFieldValue(props.name, value)
  }

  return (
    <div className={props.hidden ? "_display-none" : ""}>
      <Text className="_text-2 _font">
        {props.label}
        {props.required && props.label && " *"}
      </Text>

      <Form.Item hasFeedback name={props.name} rules={rules}>
        <Time
          {...props}
          allowClear={props.allowClear}
          bordered={props.bordered}
          defaultValue={props.defaultValue}
          disabled={props.disabled}
          loading={props.loading}
          onSelectMobile={onInternalSelect}
          showSearch={props.showSearch}
        />
      </Form.Item>
    </div>
  )
}

export default FormTime
