import React from "react"

import { Spin } from "antd"
import Text from "components/atom/text"

const Loading: React.FC<any> = ({
  center,
  className,
  text,
  withText = true,
}) => {
  return (
    <div className={`${center ? "_text-center" : ""} ${className}`}>
      {withText && (
        <Text className="_text-4 _font-weight-bold">
          {text ? text : "Carregando aguarde..."}
        </Text>
      )}
      <div className="_mt-4">
        <Spin size="large" />
      </div>
    </div>
  )
}

export const Rendering: React.FC<any> = (props) => {
  return (
    <div className={`_text-center ${props.className}`}>
      <Text className="_text-4 _font-weight-bold">Processando aguarde...</Text>
      <div className="_mt-4">
        <Spin size="large" />
      </div>
    </div>
  )
}

export default Loading
