import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

import { AppDispatch, RootState } from "@/store"

import { checkToken } from "./checktokenSlice"

export const useChecktokenStore = () => {
  const dispatch = useDispatch<AppDispatch>()
  const checkTokenState = useSelector((state: RootState) => state.checkToken)

  const executeCheckToken = useCallback(
    () => dispatch(checkToken()),
    [dispatch],
  )

  return {
    data: checkTokenState.data,
    executeCheckToken,
    viewState: checkTokenState.viewState,
  }
}
