import FetchApi from "libs/fetch"
import TrackLog from "libs/trackLog"
import { Dispatch } from "redux"
import * as ActionUtility from "store/utilities/actionUtility"

import { mappedApis } from "@/libs/fetch/apis"

import ActionType from "./ActionType"

const TAG_LOG_FILE = "Store_CreditCard_Effect"

export interface CreateCreditCard {
  brand: string
  cardNumber: string
  customerName: string
  expirationMonth: string
  expirationYear: string
  holder: string
  typeNumberId: string
}

export const creditcard = async () => {
  const fetch = await FetchApi.get({
    hasAuth: true,
    url: mappedApis.base.creditcard.list.get.url,
  })

  return {
    data: fetch.data,
    hasError: FetchApi.hasError(fetch.status),
  }
}

export const createCreditCard = async (data: CreateCreditCard) => {
  try {
    const fetch = await FetchApi.post({
      hasAuth: true,
      params: {
        ...data,
        customerName: data.holder,
        typeNumberId: data.typeNumberId || "cpf",
      },
      url: mappedApis.base.creditcard.create.post.url,
    })

    return {
      data: fetch.data,
      hasError: FetchApi.hasError(fetch.status),
    }
  } catch (e) {
    TrackLog.logError(TAG_LOG_FILE, createCreditCard.name, e)

    return {
      data: null,
      hasError: true,
    }
  }
}

export const defaultCreditcard = async (cardHash: string) => {
  const fetch = await FetchApi.put({
    hasAuth: true,
    params: { cardHash },
    url: mappedApis.base.creditcard.default.put.url,
  })

  return {
    data: fetch.data,
    hasError: FetchApi.hasError(fetch.status),
  }
}

export const setLoading = (dispatch: Dispatch, payload: boolean) =>
  dispatch(
    ActionUtility.createAction(ActionType.CREDITCARD_DATA_LOADING, payload),
  )

export const setLoadingCreate = (dispatch: Dispatch, payload: boolean) =>
  dispatch(
    ActionUtility.createAction(ActionType.CREDITCARD_CREATE_LOADING, payload),
  )

export const setData = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.CREDITCARD_DATA, payload))

export const setError = (dispatch: Dispatch, payload: any) =>
  dispatch(
    ActionUtility.createAction(ActionType.CREDITCARD_DATA_ERROR, payload),
  )

export const setErrorCreate = (dispatch: Dispatch, payload: any) =>
  dispatch(
    ActionUtility.createAction(ActionType.CREDITCARD_CREATE_ERROR, payload),
  )

export const setSelected = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.CREDITCARD_SELECTED, payload))

export const setSecurityData = (dispatch: Dispatch, payload: any) =>
  dispatch(
    ActionUtility.createAction(ActionType.CREDITCARD_SECURITY_DATA, payload),
  )
