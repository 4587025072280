import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"

import { SmileTwoTone } from "@ant-design/icons"
import Button from "components/atom/button"
import Text from "components/atom/text"
import Footer from "components/organism/footer"
import Header from "components/organism/header"
import ManageDate from "libs/date"
import qs from "qs"

import Card from "@/components/molecule/card"

import CardGroupVehicle from "../UserSearchVehicleAvailable/components/cardGroup"
import { VehicleSearched } from "../UserSearchVehicleAvailable/types"

export interface QuerySearch {
  end?: string
  movidaId?: string
  reservationCode?: string
  start?: string
}

const UserRentDone = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory<VehicleSearched>()
  const vehicle = history.location.state

  const params: QuerySearch =
    qs.parse(location.search, { ignoreQueryPrefix: true }) || {}
  const formatDate = ManageDate.formatRentDone(params.start, params.end)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onClickReservation = () => {
    history.push("/account/user")
  }

  return (
    <div>
      <Header />
      <div className="px-4 bg-slate-200 flex justify-center">
        <div className="max-w-[1280px] w-full">
          <Card className="mt-6 text-center">
            <div className="flex flex-col items-center">
              <SmileTwoTone className="text-5xl" />
              <Text className="mt-4 text-lg">{t("rent_done_info")}</Text>
            </div>

            <div>
              <Text className="mt-4 text-lg block">
                {t("reservation_code")}
              </Text>
              <Text className="my-4 text-3xl font-bold block">
                {params.reservationCode}
              </Text>
              <Button
                block
                onClick={onClickReservation}
                theme="outline-primary"
              >
                {t("go_my_reservation")}
              </Button>
            </div>
          </Card>

          <Card className="mt-6">
            <div className="grid md:grid-cols-2 gap-6">
              <div className="col-span-2 md:col-span-1">
                <div className="mb-2 font-bold">{t("pick_up")}</div>
                <div className="text-xl">{formatDate.startDayMonth}</div>
                <div className="text-3xl font-bold">{formatDate.startTime}</div>
                <div className="mt-4 mr-4 text-lg">
                  {vehicle.selectedPickup.local}
                  {vehicle.selectedPickup?.additionalInfo
                    ? ` - ${vehicle.selectedPickup?.additionalInfo}`
                    : ""}
                </div>
              </div>

              <div className="col-span-2 md:col-span-1">
                <div className="mb-2 font-bold">{t("drop_off")}</div>
                <div className="text-xl">{formatDate.endDayMonth}</div>
                <div className="text-3xl font-bold">{formatDate.endTime}</div>
                <div className="mt-4 mr-4 text-lg">
                  {vehicle.selectedDropoff.local}
                  {vehicle.selectedDropoff?.additionalInfo
                    ? ` - ${vehicle.selectedDropoff?.additionalInfo}`
                    : ""}
                </div>
              </div>
            </div>
          </Card>

          <CardGroupVehicle
            className="mt-6 mb-6 md:pl-4"
            showPrice={false}
            vehicle={vehicle}
          />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default UserRentDone
