import React, { useState, useRef, useImperativeHandle } from "react"

import { CameraOutlined } from "@ant-design/icons"
import { Divider, Modal, Skeleton } from "antd"
import Button from "components/atom/button"
import Grid, { GridSize } from "components/atom/grid"
import ImageLoad from "components/atom/image_load"
import FormImage from "components/molecule/form_image"
import { WebCamDisplay } from "components/organism/webcam_display"
import * as DocumentEffect from "store/document/Effect"

import "./styles.scss"

export enum DocumentUploadType {
  CNH = "CNH_USER_DOCUMENT",
  PROOF_RESIDENCE = "PROOF_RESIDENCE_USER_DOCUMENT",
  PROOF_ADDITIONAL_DOCUMENT = "PROOF_ADDITIONAL_DOCUMENT_USER_DOCUMENT",
}

export interface TakePhotoDocumentModalRefProps {
  dismiss: () => void
  show: () => void
}

export const useTakePhotoDocument = () => {
  const modal = useRef<TakePhotoDocumentModalRefProps>(null)

  const show = () => modal.current?.show()
  const dismiss = () => modal.current?.dismiss()

  return { dismiss, ref: modal, show }
}

interface Props {
  allowedExtensions?: Array<string>
  documentType: DocumentUploadType
  initalPhotoDocumentUrl?: string
  onSuccess?: (data: any) => void
  title?: string
}

const TakePhotoDocumentModal = React.forwardRef<
  TakePhotoDocumentModalRefProps,
  Props
>(
  (
    {
      allowedExtensions,
      documentType,
      initalPhotoDocumentUrl,
      onSuccess,
      title,
    },
    ref,
  ) => {
    const [visible, setVisible] = useState(false)
    const [documentUrl, setDocumentUrl] = useState(initalPhotoDocumentUrl)
    const [isCameraVisible, setCameraVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [uploadData, setUploadData] = useState<any>({})

    const show = () => {
      setVisible(true)
    }

    const dismiss = () => {
      setCameraVisible(false)

      // Fix this to remove the timeout;
      setTimeout(() => {
        onRetakePhoto()
        setVisible(false)
      }, 500)
    }

    useImperativeHandle(ref, () => ({
      dismiss,
      show,
    }))

    const handleOk = () => {
      onSuccess?.(uploadData)
      if (!uploadData.hasError) {
        setDocumentUrl(uploadData.url)
      }
      setUploadData(null)
      setCameraVisible(false)
      dismiss()
    }

    const handleClose = () => {
      setCameraVisible(false)

      // Fix this to remove the timeout;
      setTimeout(() => {
        onRetakePhoto()
        setVisible(false)
      }, 500)
    }

    const onSuccessUpload = (data: any) => {
      setUploadData(data)
    }

    const onErrorUpload = () => {
      onRetakePhoto()
    }

    const onCameraCapturePhoto = async (photo: string) => {
      setLoading(true)
      const result = await DocumentEffect.uploadBase64({ file: photo })
      setUploadData(result)
      setCameraVisible(false)
      setLoading(false)
    }

    const onRetakePhoto = () => {
      setUploadData(null)
      setCameraVisible(false)
      setLoading(false)
    }

    return (
      <Modal
        closable={!loading}
        footer={[
          <Button
            className="_mr-2"
            disabled={loading}
            key="back"
            onClick={handleClose}
            theme="outline-black"
          >
            Fechar
          </Button>,
          ...(uploadData?.url
            ? [
                <Button
                  disabled={loading}
                  key="submit"
                  onClick={handleOk}
                  theme="primary"
                >
                  Salvar nova foto
                </Button>,
              ]
            : []),
        ]}
        onCancel={handleClose}
        onOk={handleOk}
        open={visible}
        title={title || "Foto do documento"}
        width={690}
      >
        {loading ? (
          <div className="flex flex-col items-center">
            <Skeleton.Image active className="mb-4" />
            <div className="text-xl">Estamos carregando a imagem...</div>
            <div className="mt-2 text-base font-bold">
              Não feche essa janela.
            </div>
          </div>
        ) : (
          <>
            {uploadData?.url ? (
              <>
                <ImageLoad url={uploadData.url} />
                <div className="_flex-diplay _justify-content-center _mt-4">
                  <Button
                    block
                    key="submit"
                    onClick={onRetakePhoto}
                    theme="secondary"
                  >
                    Escolher outra foto
                  </Button>
                </div>
              </>
            ) : (
              <>
                {isCameraVisible ? (
                  <>
                    <WebCamDisplay onCapture={onCameraCapturePhoto} />
                  </>
                ) : (
                  <>
                    <Grid size={GridSize.Two}>
                      <div className="take-photo-box-container">
                        <div
                          className="take-photo-box"
                          onClick={() => setCameraVisible(true)}
                        >
                          <CameraOutlined className="molecule-form-image-box__icon" />
                        </div>
                        <div className="_text-3 _font-weight-bold _mt-4">
                          Tirar foto
                        </div>
                      </div>
                      <FormImage
                        allowedExtensions={allowedExtensions}
                        documentType={documentType}
                        name="documentFile"
                        onError={onErrorUpload}
                        onLoadingEnd={() => setLoading(false)}
                        onLoadingStart={() => setLoading(true)}
                        onSuccess={onSuccessUpload}
                        title={
                          documentUrl
                            ? "Carregar nova foto"
                            : "Carregar do computador"
                        }
                      />
                    </Grid>
                    {documentUrl && (
                      <>
                        <Divider />
                        <div className="_text-3 _font-weight-bold _mb-4 _text-center">
                          {" "}
                          Foto Atual{" "}
                        </div>
                        <ImageLoad ableToOpen={true} url={documentUrl} />
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </Modal>
    )
  },
)

TakePhotoDocumentModal.displayName = "TakePhotoDocumentModal"

export default TakePhotoDocumentModal
