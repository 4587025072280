import React, { useRef, useState } from "react"
import Webcam from "react-webcam"

import Button from "components/atom/button"

import useDeviceDetection from "@/hooks/useDeviceDetection"

export const WebCamDisplay = ({ onCapture }: any) => {
  const device = useDeviceDetection()
  const webcamRef = useRef<Webcam>(null)
  const [sideCamera, setSideCamera] = useState("front")

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef?.current?.getScreenshot()
    onCapture?.(imageSrc)
  }, [webcamRef, onCapture])

  const flipCameraSide = () => {
    if (sideCamera === "front") {
      setSideCamera("back")
    } else {
      setSideCamera("front")
    }
  }

  const videoConstraints =
    sideCamera === "front"
      ? { facingMode: "user" }
      : { facingMode: { exact: "environment" } }

  return (
    <>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
      />
      <div className="grid grid-cols-2 lg:grid-cols-1 mt-4">
        <Button block onClick={capture} theme="secondary">
          Tirar foto
        </Button>
        {(device === "mobile" || device === "table") && (
          <Button className="ml-4" onClick={flipCameraSide} theme="secondary">
            Girar camera
          </Button>
        )}
      </div>
    </>
  )
}
