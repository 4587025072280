import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"

import { Form } from "antd"
import Text from "components/atom/text"
import AutoComplete, {
  AutoCompleteRefProps,
} from "components/molecule/autocomplete"
import { logEvent } from "firebase/analytics"
import ManageDate from "libs/date"
import { analytics } from "libs/firebase"
import moment from "moment"
import { FeatureGetter } from "store/getter/Action"
import { useGetterStore } from "store/getter/Hooks"

import ManageUrl from "@/libs/url"

import { PlaceSelected } from "."
import FormDatePicker from "../form_datepicker"
import FormTime from "../form_time"

export interface QuerySearch {
  dropoff?: PlaceSelected
  end?: string
  endHour?: string
  pickup?: PlaceSelected
  start?: string
  startHour?: string
}

type SearchFormProps = {
  start?: string
  end?: string
  pickupPlace?: string
  dropoffPlace?: string
  onSubmit?: (data: any) => void
}

export type SearchQueryParams = {
  start?: string
  end?: string
  pickup?: string
  dropoff?: string
}

function SearchForm(props: SearchFormProps) {
  const { t } = useTranslation()

  const location = useLocation()
  const params: SearchQueryParams =
    ManageUrl.parseQueryParams(location.search) || {}

  const [form] = Form.useForm()

  const refDropoff = useRef<AutoCompleteRefProps>(null)
  const refPickup = useRef<AutoCompleteRefProps>(null)

  const [query, setQuery] = useState<QuerySearch>({})
  const [validate, showValidate] = useState<{
    pickup: boolean
    dropoff: boolean
  }>({ dropoff: false, pickup: false })
  const [searchDisabled, setSearchDisabled] = useState<boolean>(true)

  const { getData: getDataPickupDropoff } = useGetterStore({
    feature: FeatureGetter.GetPlacesPickupDropoff,
  })

  const getPickupDropoffPlace = async () => {
    const dataPickup = await getDataPickupDropoff({
      queryParams: { uCode: params.pickup },
    })

    if (dataPickup) {
      setQuery((prevState: any) => ({ ...prevState, pickup: dataPickup }))
      refPickup.current?.selectItem({ value: dataPickup?.place })

      if (params.dropoff && params.pickup !== params.dropoff) {
        const dataDropoff = await getDataPickupDropoff({
          queryParams: { uCode: params.dropoff },
        })
        setQuery((prevState: any) => ({ ...prevState, dropoff: dataDropoff }))
        refDropoff.current?.selectItem({ value: dataDropoff?.place })
      } else {
        setQuery((prevState: any) => ({ ...prevState, dropoff: dataPickup }))
        refDropoff.current?.selectItem({ value: dataPickup?.place })
      }
    }

    setSearchDisabled(false)
  }

  useEffect(() => {
    getPickupDropoffPlace()
  }, [])

  const getInitialDates = ({ end, start }: any) => {
    return {
      dropoffDate: end ? ManageDate.mountDate(end) : ManageDate.nowAddDays(5),
      dropoffHour: end ? ManageDate.formatHourAndMinute(end) : "10:00",
      pickupDate: start
        ? ManageDate.mountDate(start)
        : ManageDate.nowAddDays(2),
      pickupHour: start ? ManageDate.formatHourAndMinute(start) : "10:00",
    }
  }

  const disabledPickupDate = (current: any) => {
    return current && current < moment().endOf("day")
  }

  const disabledDropoffDate = (current: any) => {
    const pickupDate = form.getFieldValue("pickupDate")
    return current && current < pickupDate
  }

  const onSelectPickup = (value: PlaceSelected) => {
    setQuery((prevState: any) => ({ ...prevState, pickup: value }))
    showValidate((prevState: any) => ({ ...prevState, pickup: false }))

    refDropoff.current?.selectItem({ value: value.place })
    onSelectDropoff(value)
  }

  const onSelectDropoff = (value: PlaceSelected) => {
    setQuery((prevState: any) => ({ ...prevState, dropoff: value }))
    showValidate((prevState: any) => ({ ...prevState, dropoff: false }))
  }

  const onSelectPickupDate = (selected: any) => {
    const format = selected.format("YYYY-MM-DDTHH:mm")
    const newDate = ManageDate.addDays(format, 3)
    form.setFieldValue("dropoffDate", ManageDate.mountDate(newDate))
  }

  const onBlur = (type: string) => {
    // @ts-expect-error - string index;
    if (!query[type]?.uCode) {
      setQuery((prevState: any) => ({ ...prevState, [type]: null }))
      showValidate((prevState: any) => ({ ...prevState, [type]: true }))
    }
  }

  const onChangeValue = (type: string) => {
    setQuery((prevState: any) => ({ ...prevState, [type]: null }))
  }

  const onInternalSubmit = (data: any) => {
    showValidate({ dropoff: false, pickup: false })

    if (!query.pickup?.uCode || !query.dropoff?.uCode) {
      showValidate({
        dropoff: !query.dropoff?.uCode,
        pickup: !query.pickup?.uCode,
      })
      return false
    }

    logEvent(analytics, `searched_by_user`, {
      city: query.pickup.city,
    })

    const formData = {
      dropoff: query.dropoff,
      end: `${data.dropoffDate?.format("YYYY-MM-DD")}T${data.dropoffHour}`,
      pickup: query.pickup,
      start: `${data.pickupDate?.format("YYYY-MM-DD")}T${data.pickupHour}`,
    }

    props.onSubmit?.(formData)
  }

  return (
    <Form
      form={form}
      initialValues={getInitialDates(props)}
      name="basic"
      onFinish={onInternalSubmit}
    >
      <div className="grid max-sm:grid-rows-2 md:grid-cols-12">
        <div className="max-sm:row-span-1 md:col-span-6 flex flex-col">
          <Text className="block text-base">{t("pickup_place")}</Text>

          <AutoComplete
            allowClear={true}
            className="_mt-2"
            defaultValue={props.pickupPlace ? props.pickupPlace : undefined}
            disabled={searchDisabled}
            // onKeyDown={onSearchingPressEnter}
            feature={FeatureGetter.GetPlaces}
            keyData="place"
            multiSelect={true}
            onBlur={() => onBlur("pickup")}
            onChangeValue={() => onChangeValue("pickup")}
            onSelect={(value: PlaceSelected) => onSelectPickup(value)}
            placeholder={"digite o local de retirada"}
            queryParam="place"
            ref={refPickup}
            status={validate.pickup ? "error" : undefined}
          />
          {validate.pickup && (
            <div className="_mt-2">
              Você deve selecionar um local de retirada.
            </div>
          )}
        </div>
        <div className="md:ml-4 max-sm:row-span-1 md:col-span-4 grid grid-cols-12">
          <div className="col-span-7 mr-2">
            <Text className="block text-base">Data</Text>
            <div className="mt-2">
              <FormDatePicker
                disabledDate={disabledPickupDate}
                inputReadOnly
                name="pickupDate"
                onSelect={onSelectPickupDate}
                required
              />
            </div>
          </div>
          <div className="col-span-5">
            <Text className="block text-base">Hora</Text>
            <div className="mt-2">
              <FormTime
                allowClear={true}
                name="pickupHour"
                required
                showSearch={true}
              />
            </div>
          </div>
          <div className="hidden md:block md:col-span-2">&nbsp;</div>
        </div>
      </div>

      <div className="grid max-sm:grid-rows-2 md:grid-cols-12">
        <div className="max-sm:row-span-1 md:col-span-6">
          <Text className="block text-base">{t("dropoff_place")}</Text>

          <AutoComplete
            allowClear={true}
            className="_mt-2"
            defaultValue={props.dropoffPlace ? props.dropoffPlace : undefined}
            disabled={searchDisabled}
            // onKeyDown={onSearchingPressEnter}
            feature={FeatureGetter.GetPlaces}
            keyData="place"
            multiSelect={true}
            onChangeValue={() => onChangeValue("dropoff")}
            onSelect={(value: PlaceSelected) => onSelectDropoff(value)}
            placeholder={"digite o local de devolução"}
            queryParam="place"
            ref={refDropoff}
            status={validate.dropoff ? "error" : undefined}
          />
          {validate.dropoff && (
            <div className="_mt-2">
              Você deve selecionar um o local de devolução.
            </div>
          )}
        </div>
        <div className="md:ml-4 max-sm:row-span-1 md:col-span-4 grid grid-cols-12">
          <div className="col-span-7 mr-2">
            <Text className="block text-base">Data</Text>
            <div className="mt-2">
              <FormDatePicker
                disabledDate={disabledDropoffDate}
                inputReadOnly
                name="dropoffDate"
                required
              />
            </div>
          </div>
          <div className="col-span-5">
            <Text className="block text-base">Hora</Text>
            <div className="mt-2">
              <FormTime
                allowClear={true}
                name="dropoffHour"
                required
                showSearch={true}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center max-sm:mb-4 md:ml-4 md:col-span-2">
          <div
            className="py-3 w-full cursor-pointer bg-closecar-primary rounded-full text-center"
            onClick={() => form.submit()}
          >
            <span className="text-lg font-bold text-white">Buscar</span>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default SearchForm
