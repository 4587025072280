import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"
import FetchApi from "libs/fetch"
import { StateMachineType } from "libs/statemachine"
import Storage from "libs/storage"

import { mappedApis } from "@/libs/fetch/apis"

interface State {
  info: {
    viewState: StateMachineType
    data: any
    error: boolean
  }
  update: {
    data: any
    viewState: StateMachineType
    error: boolean
  }
  verify: {
    data: any
    viewState: StateMachineType
    error: boolean
  }
}
const initialState: State = {
  info: {
    data: {},
    error: false,
    viewState: StateMachineType.NotStarted,
  },
  update: {
    data: {},
    error: false,
    viewState: StateMachineType.NotStarted,
  },
  verify: {
    data: {},
    error: false,
    viewState: StateMachineType.NotStarted,
  },
}

export const accountInfo = createAsyncThunk("account/info", async () => {
  const fetch = await FetchApi.get({
    hasAuth: true,
    options: {
      autoAbort: true,
    },
    url: mappedApis.base.account.info.get.url,
  })
  return fetch.data
})

export const accountUpdate = createAsyncThunk(
  "account/update",
  async (params: any) => {
    const fetch = await FetchApi.put({
      hasAuth: true,
      params,
      url: mappedApis.base.account.update.put.url,
    })

    if (fetch.status !== FetchApi.HttpSuccessfulOk) {
      throw new Error(fetch.data.key)
    }

    return fetch
  },
)

export const accountVerify = createAsyncThunk(
  "account/verify",
  async ({ email }: { email: string }) => {
    const fetch = await FetchApi.post({
      hasAuth: true,
      params: { email },
      url: mappedApis.base.account.check.post.url,
    })
    return fetch.data
  },
)

export const favoriteCompany = createAsyncThunk(
  "account/favoriteCompany",
  async (body: any) => {
    const fetch = await FetchApi.post({
      hasAuth: true,
      params: body,
      url: mappedApis.base.account.companyFavorite.post.url,
    })
    return fetch.data
  },
)

const accountSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(accountInfo.pending, (state) => {
        state.info.viewState = StateMachineType.Loading
      })
      .addCase(accountInfo.fulfilled, (state, action: PayloadAction<any>) => {
        state.info.viewState = StateMachineType.Loaded
        state.info.data = action.payload
      })
      .addCase(accountInfo.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          return
        }

        state.info.viewState = StateMachineType.Error
        state.info.error = true
      })
      .addCase(accountUpdate.pending, (state) => {
        state.update.viewState = StateMachineType.Loading
      })
      .addCase(accountUpdate.fulfilled, (state) => {
        state.update.viewState = StateMachineType.Loaded
        state.update.error = false
      })
      .addCase(accountUpdate.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          return
        }

        state.update.viewState = StateMachineType.Error
        state.update.error = true
        state.update.data = { key: action.error.message }
      })
      .addCase(accountVerify.pending, (state) => {
        state.verify.viewState = StateMachineType.Loading
      })
      .addCase(accountVerify.fulfilled, (state, action) => {
        state.verify.viewState = StateMachineType.Loaded
        state.verify.data = action.payload
        state.verify.error = false
      })
      .addCase(accountVerify.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          return
        }

        state.verify.viewState = StateMachineType.Error
        state.verify.error = true
      })
  },
  initialState,
  name: "account",
  reducers: {
    accountLogout: (state) => {
      state.info = initialState.info
      Storage.clear()
    },
    cleanStateVerify: (state) => {
      state.verify = initialState.verify
    },
  },
})

export const { accountLogout, cleanStateVerify } = accountSlice.actions
export default accountSlice.reducer
